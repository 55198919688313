import {__} from 'utils/i18n'
import {warn} from 'utils/log'

import {ReactComponent as IconAcFailure} from 'ipmp-react-ui/icons/worries/ac-failure.svg'
import {ReactComponent as IconBypass} from 'ipmp-react-ui/icons/worries/bypass.svg'
import {ReactComponent as IconFire} from 'ipmp-react-ui/icons/worries/fire.svg'
import {ReactComponent as IconInactive} from 'ipmp-react-ui/icons/worries/inactive.svg'
import {ReactComponent as IconJammed} from 'ipmp-react-ui/icons/worries/jammed.svg'
import {ReactComponent as IconLowBattery} from 'ipmp-react-ui/icons/worries/low-battery.svg'
import {ReactComponent as IconOpened} from 'ipmp-react-ui/icons/worries/opened.svg'
import {ReactComponent as IconCO} from 'ipmp-react-ui/icons/worries/co.svg'
import {ReactComponent as IconRssiWarning} from 'ipmp-react-ui/icons/worries/rssi.svg'
import {ReactComponent as IconSoak} from 'ipmp-react-ui/icons/worries/soak.svg'
import {ReactComponent as IconClean} from 'ipmp-react-ui/icons/worries/clean.svg'
import {ReactComponent as IconMasking} from 'ipmp-react-ui/icons/worries/masking.svg'
import {ReactComponent as IconSim} from 'ipmp-react-ui/icons/worries/sim.svg'
import {ReactComponent as IconMarkForService} from 'ipmp-react-ui/icons/worries/mark-for-service.svg'
import {ReactComponent as IconNotDiscovered} from 'ipmp-react-ui/icons/worries/no-discovered.svg'
import {ReactComponent as IconGeneralAlert} from 'ipmp-react-ui/icons/worries/alert.svg'
import {ReactComponent as IconGeneralTrouble} from 'ipmp-react-ui/icons/worries/trouble.svg'
import {ReactComponent as IconWentOffline} from 'ipmp-react-ui/icons/worries/went-offline.svg'
import {ReactComponent as IconTamper} from 'ipmp-react-ui/icons/worries/tamper.svg'
import {ReactComponent as IconGas} from 'ipmp-react-ui/icons/worries/gas.svg'
import {ReactComponent as IconFreezer} from 'ipmp-react-ui/icons/worries/freezer.svg'
import {ReactComponent as IconHot} from 'ipmp-react-ui/icons/worries/hot.svg'
import {ReactComponent as IconCold} from 'ipmp-react-ui/icons/worries/cold.svg'
import {ReactComponent as IconReportInvalid} from 'ipmp-react-ui/icons/worries/report_invalid.svg'
import {ReactComponent as IconBbaTrouble} from 'ipmp-react-ui/icons/worries/bba_trouble.svg'
import {ReactComponent as IconGprsTrouble} from 'ipmp-react-ui/icons/worries/gprs.svg'
import {ReactComponent as IconWiFiTrouble} from 'ipmp-react-ui/icons/wi-fi.svg'

export const ALERT_TYPE_FREEZER_ALERT = 'FREEZER_ALERT'
export const ALERT_TYPE_FREEZING_ALERT = 'FREEZING_ALERT'
export const ALERT_TYPE_COLD_ALERT = 'COLD_ALERT'
export const ALERT_TYPE_HOT_ALERT = 'HOT_ALERT'
export const ALERT_TYPE_CO = 'CO'
export const ALERT_TYPE_WENT_OFFLINE = 'WENT_OFFLINE'
export const ALERT_TYPE_GPRS_WENT_OFFLINE = 'GPRS_WENT_OFFLINE'
export const ALERT_TYPE_BBA_WENT_OFFLINE = 'BBA_WENT_OFFLINE'
export const ALERT_TYPE_PLINK_WENT_OFFLINE = 'PLINK_WENT_OFFLINE'

export const TROUBLE_TYPE_OPENED = 'OPENED'
export const TROUBLE_TYPE_LOW_BATTERY = 'LOW_BATTERY'
export const TROUBLE_TYPE_INACTIVE = 'INACTIVE'
export const TROUBLE_TYPE_TAMPER = 'TAMPER'
export const TROUBLE_TYPE_TROUBLE = 'TROUBLE'
export const TROUBLE_TYPE_AC_FAILURE = 'AC_FAILURE'
export const TROUBLE_TYPE_NET_TROUBLE = 'NET_TROUBLE'
export const TROUBLE_TYPE_ONE_WAY = '1_WAY'
export const TROUBLE_TYPE_CLEAN_ME = 'CLEAN_ME'
export const TROUBLE_TYPE_HEAT_TROUBLE = 'HEAT_TROUBLE'
export const TROUBLE_TYPE_HEAT_OPEN = 'HEAT_OPEN'
export const TROUBLE_TYPE_SMOKE_TROUBLE = 'SMOKE_TROUBLE'
export const TROUBLE_TYPE_SMOKE_OPEN = 'SMOKE_OPEN'
export const TROUBLE_TYPE_ONE_WAY_REPORTED = '1_WAY_REPORTED'
export const TROUBLE_TYPE_LINE_FAILURE = 'LINE_FAILURE'
export const TROUBLE_TYPE_RSSI_LOW = 'RSSI_LOW'
export const TROUBLE_TYPE_MASKING_TROUBLE = 'MASKING_TROUBLE'
export const TROUBLE_TYPE_JAMMED = 'JAMMED'
export const TROUBLE_TYPE_BYPASS = 'BYPASS'
export const TROUBLE_TYPE_SOAK_FAILED = 'SOAK_FAILED'
export const TROUBLE_TYPE_POOR_OR_LESS_NOW = 'POOR_OR_LESS_NOW'
export const TROUBLE_TYPE_POOR_OR_LESS_24H = 'POOR_OR_LESS_24H'
export const TROUBLE_TYPE_PREENROLL_NO_CODE = 'PREENROLL_NO_CODE'
export const TROUBLE_TYPE_MODULE_LOW_BATTERY = 'MODULE_LOW_BATTERY'
export const TROUBLE_TYPE_DEVICE_FAULT = 'DEVICE_FAULT'
export const TROUBLE_TYPE_DEVICE_DELINQUENCY = 'DEVICE_DELINQUENCY'
export const TROUBLE_TYPE_FAILURE_COMMUNICATE = 'FAILURE_COMMUNICATE'
export const TROUBLE_TYPE_GSIP_RECEIVER = 'GSIP_RECEIVER'
export const TROUBLE_TYPE_ALL_RECEIVER_NOT_AVAILABLE = 'ALL_RECEIVER_NOT_AVAILABLE'
export const TROUBLE_TYPE_ALL_RECEIVER_SUPERVISION = 'ALL_RECEIVER_SUPERVISION'
export const TROUBLE_TYPE_DEVICE_LOW_SENSIVITY = 'DEVICE_LOW_SENSIVITY'
export const TROUBLE_TYPE_DEVICE_INTERNAL_FAULT = 'DEVICE_INTERNAL_FAULT'
export const TROUBLE_TYPE_RF_DEVICE_NOT_NETWORKED = 'RF_DEVICE_NOT_NETWORKED'
export const TROUBLE_TYPE_SERVICE_REQUEST = 'SERVICE_REQUEST'
export const TROUBLE_TYPE_BELL = 'BELL'
export const TROUBLE_TYPE_FIRE_TROUBLE = 'FIRE_TROUBLE'
export const TROUBLE_TYPE_CO_TROUBLE = 'CO_TROUBLE'
export const TROUBLE_TYPE_GROUND_FAULT = 'GROUND_FAULT'
export const TROUBLE_TYPE_OUTPUT_FAULT = 'OUTPUT_FAULT'
export const TROUBLE_TYPE_TLM = 'TLM'
export const TROUBLE_TYPE_PRINTER = 'PRINTER'
export const TROUBLE_TYPE_TIME_DATE = 'TIME_DATE'
export const TROUBLE_TYPE_CONFIGURATION = 'CONFIGURATION'
export const TROUBLE_TYPE_SIM_LOCK = 'SIM_LOCK'
export const TROUBLE_TYPE_GSM = 'GSM'
export const TROUBLE_TYPE_ETHERNET = 'ETHERNET'
export const TROUBLE_TYPE_MODULE_SUPERVISORY = 'MODULE_SUPERVISORY'
export const TROUBLE_TYPE_MODULE_AC = 'MODULE_AC'
export const TROUBLE_TYPE_MODULE_BATTERY_MISSING = 'MODULE_BATTERY_MISSING'
export const TROUBLE_TYPE_MODULE_BATTERY_CHARGER = 'MODULE_BATTERY_CHARGER'
export const TROUBLE_TYPE_MODULE_BATTERY_BUS_LOW_VOLTAGE =
    'MODULE_BATTERY_BUS_LOW_VOLTAGE'
export const TROUBLE_TYPE_MODULE_AUX = 'MODULE_AUX'
export const TROUBLE_TYPE_FIRMWARE_UPGRADE = 'FIRMWARE_UPGRADE'
export const TROUBLE_TYPE_DEFAULT_CODE = 'DEFAULT_CODE'
export const TROUBLE_TYPE_DUPLICATE_CODE = 'DUPLICATE_CODE'
export const TROUBLE_TYPE_GAS_TROUBLE = 'GAS_TROUBLE'
export const TROUBLE_TYPE_FREEZER = 'FREEZER'
export const TROUBLE_TYPE_PROBE = 'PROBE'
export const TROUBLE_TYPE_RADIO_SIM = 'RADIO_SIM'
export const TROUBLE_TYPE_MODULE_BATTERY_DISCONNECTED = 'MODULE_BATTERY_DISCONNECTED'
export const TROUBLE_TYPE_RADIO_LOW_SIGNAL = 'RADIO_LOW_SIGNAL'
export const TROUBLE_TYPE_GSM_NETWORK = 'GSM_NETWORK'
export const TROUBLE_TYPE_IP_REMOTE_SERVER = 'IP_REMOTE_SERVER'
export const TROUBLE_TYPE_GSM_NETWORK_LOW_INPUT_VOLTAGE = 'GSM_NETWORK_LOW_INPUT_VOLTAGE'
export const TROUBLE_TYPE_LOW_OUTPUT = 'LOW_OUTPUT'
export const TROUBLE_TYPE_MODULE_TAMPER = 'MODULE_TAMPER'
export const TROUBLE_TYPE_SYSTEM_OVERVIEW = 'SYSTEM_OVERVIEW'
export const TROUBLE_TYPE_WARM_START = 'WARM_START'
export const TROUBLE_TYPE_USB_WIFI = 'USB_WIFI'
export const TROUBLE_TYPE_DEVICE_MASK = 'DEVICE_MASK'
export const TROUBLE_TYPE_POWER_UNIT_FAILURE = 'POWER_UNIT_FAILURE'
export const TROUBLE_TYPE_OVERCURRENT = 'OVERCURRENT'
export const TROUBLE_TYPE_MODULE_BATTERY_2_LOW_VOLTAGE = 'MODULE_BATTERY_2_LOW_VOLTAGE'
export const TROUBLE_TYPE_MODULE_BATTERY_2_MISSING = 'MODULE_BATTERY_2_MISSING'
export const TROUBLE_TYPE_MODULE_AUX_2 = 'MODULE_AUX_2'
export const TROUBLE_TYPE_MODULE_BUS_FAULT = 'MODULE_BUS_FAULT'

/* Trouble in panels: */
export const TROUBLE_TYPE_PANEL_COMM_FAILURE = 'COMM_FAILURE'
export const TROUBLE_TYPE_PANEL_FUSE = 'FUSE'
export const TROUBLE_TYPE_PANEL_NO_ACTIVE = 'NO_ACTIVE'
export const TROUBLE_TYPE_PANEL_MARK_FOR_SERVICE = 'MARK_FOR_SERVICE'
export const TROUBLE_TYPE_PANEL_NOT_DISCOVERED = 'NOT_DISCOVERED'
export const TROUBLE_TYPE_PANEL_SIM_NOT_VERIFIED = 'SIM_NOT_VERIFIED'
export const TROUBLE_TYPE_PANEL_NOT_ACTIVATED = 'NOT_ACTIVATED'

/* Fibro */
export const FIBRO_COMPROMISE_ATTEMPT_TROUBLE = 'FIBRO_COMPROMISE_ATTEMPT_TROUBLE'
export const FIBRO_INVALID_REPORT_TROUBLE = 'FIBRO_INVALID_REPORT_TROUBLE'
export const FIBRO_ENC_DISABLED_BBA_TROUBLE = 'FIBRO_ENC_DISABLED_BBA_TROUBLE'
export const FIBRO_ENC_DISABLED_GPRS_TROUBLE = 'FIBRO_ENC_DISABLED_GPRS_TROUBLE'
export const FIBRO_ENC_NOT_SUPPORTED_BBA_TROUBLE = 'FIBRO_ENC_NOT_SUPPORTED_BBA_TROUBLE'
export const FIBRO_ENC_NOT_SUPPORTED_GPRS_TROUBLE = 'FIBRO_ENC_NOT_SUPPORTED_GPRS_TROUBLE'
export const FIBRO_ENC_FAIL_BBA_TROUBLE = 'FIBRO_ENC_FAIL_BBA_TROUBLE'
export const FIBRO_ENC_FAIL_GPRS_TROUBLE = 'FIBRO_ENC_FAIL_GPRS_TROUBLE'
export const FIBRO_ENC_DISABLED_BBA = 'FIBRO_ENC_DISABLED_BBA'
export const FIBRO_UNENCRYPTED_BBA = 'FIBRO_UNENCRYPTED_BBA'
export const FIBRO_ENC_DISABLED_GPRS = 'FIBRO_ENC_DISABLED_GPRS'
export const FIBRO_UNENCRYPTED_GPRS = 'FIBRO_UNENCRYPTED_GPRS'
export const FIBRO_ENC_FAIL_BBA = 'FIBRO_ENC_FAIL_BBA'
export const FIBRO_ENC_FAIL_GPRS = 'FIBRO_ENC_FAIL_GPRS'
export const FIBRO_ENC_NOT_SUPPORTED_BBA = 'FIBRO_ENC_NOT_SUPPORTED_BBA'
export const FIBRO_ENC_NOT_SUPPORTED_GPRS = 'FIBRO_ENC_NOT_SUPPORTED_GPRS'

const icons = {
    [TROUBLE_TYPE_OPENED]: IconOpened,
    [TROUBLE_TYPE_LOW_BATTERY]: IconLowBattery,
    [TROUBLE_TYPE_MODULE_LOW_BATTERY]: IconLowBattery,
    [TROUBLE_TYPE_INACTIVE]: IconInactive,
    [TROUBLE_TYPE_TAMPER]: IconTamper,
    [TROUBLE_TYPE_AC_FAILURE]: IconAcFailure,
    [TROUBLE_TYPE_HEAT_TROUBLE]: IconFire,
    [TROUBLE_TYPE_HEAT_OPEN]: IconFire,
    [TROUBLE_TYPE_SMOKE_TROUBLE]: IconFire,
    [TROUBLE_TYPE_SMOKE_OPEN]: IconFire,
    [TROUBLE_TYPE_FIRE_TROUBLE]: IconFire,
    [TROUBLE_TYPE_CO_TROUBLE]: IconCO,
    [TROUBLE_TYPE_ONE_WAY]: IconRssiWarning,
    [TROUBLE_TYPE_ONE_WAY_REPORTED]: IconRssiWarning,
    [TROUBLE_TYPE_CLEAN_ME]: IconClean,
    [TROUBLE_TYPE_MASKING_TROUBLE]: IconMasking,
    [TROUBLE_TYPE_JAMMED]: IconJammed,
    [TROUBLE_TYPE_BYPASS]: IconBypass,
    [TROUBLE_TYPE_SOAK_FAILED]: IconSoak,
    [TROUBLE_TYPE_LINE_FAILURE]: IconWentOffline,
    [TROUBLE_TYPE_WARM_START]: IconHot,
    [TROUBLE_TYPE_USB_WIFI]: IconWiFiTrouble,
    [TROUBLE_TYPE_DEVICE_MASK]: IconMasking,
    [TROUBLE_TYPE_MODULE_BATTERY_2_LOW_VOLTAGE]: IconLowBattery,
    [TROUBLE_TYPE_MODULE_BATTERY_2_MISSING]: IconLowBattery,
    [TROUBLE_TYPE_PANEL_NO_ACTIVE]: IconInactive,
    [TROUBLE_TYPE_PANEL_MARK_FOR_SERVICE]: IconMarkForService,
    [TROUBLE_TYPE_PANEL_NOT_DISCOVERED]: IconNotDiscovered,
    [TROUBLE_TYPE_PANEL_SIM_NOT_VERIFIED]: IconSim,
    [TROUBLE_TYPE_SIM_LOCK]: IconSim,
    [TROUBLE_TYPE_MODULE_AC]: IconAcFailure,
    [TROUBLE_TYPE_MODULE_BATTERY_MISSING]: IconLowBattery,
    [TROUBLE_TYPE_MODULE_BATTERY_CHARGER]: IconLowBattery,
    [TROUBLE_TYPE_MODULE_BATTERY_BUS_LOW_VOLTAGE]: IconLowBattery,
    [TROUBLE_TYPE_MODULE_BATTERY_DISCONNECTED]: IconLowBattery,
    [TROUBLE_TYPE_GAS_TROUBLE]: IconGas,
    [TROUBLE_TYPE_FREEZER]: IconFreezer,
    [TROUBLE_TYPE_RADIO_LOW_SIGNAL]: IconJammed,
    [TROUBLE_TYPE_GSM_NETWORK]: IconJammed,
    [TROUBLE_TYPE_MODULE_TAMPER]: IconTamper,
    [ALERT_TYPE_COLD_ALERT]: IconCold,
    [ALERT_TYPE_FREEZER_ALERT]: IconFreezer,
    [ALERT_TYPE_FREEZING_ALERT]: IconFreezer,
    [ALERT_TYPE_HOT_ALERT]: IconHot,
    [ALERT_TYPE_CO]: IconCO,
    [ALERT_TYPE_WENT_OFFLINE]: IconWentOffline,
    [ALERT_TYPE_GPRS_WENT_OFFLINE]: IconWentOffline,
    [ALERT_TYPE_BBA_WENT_OFFLINE]: IconWentOffline,
    [ALERT_TYPE_PLINK_WENT_OFFLINE]: IconWentOffline,
    [FIBRO_INVALID_REPORT_TROUBLE]: IconReportInvalid,
    [FIBRO_ENC_DISABLED_BBA_TROUBLE]: IconBbaTrouble,
    [FIBRO_ENC_NOT_SUPPORTED_BBA_TROUBLE]: IconBbaTrouble,
    [FIBRO_ENC_FAIL_BBA_TROUBLE]: IconBbaTrouble,
    [FIBRO_ENC_DISABLED_GPRS_TROUBLE]: IconGprsTrouble,
    [FIBRO_ENC_NOT_SUPPORTED_GPRS_TROUBLE]: IconGprsTrouble,
    [FIBRO_ENC_FAIL_GPRS_TROUBLE]: IconGprsTrouble,
}

export function troubleTitle(trouble) {
    switch (trouble) {
        case ALERT_TYPE_FREEZER_ALERT:
            return __('Freezer alert')
        case ALERT_TYPE_FREEZING_ALERT:
            return __('Freezing alert')
        case ALERT_TYPE_COLD_ALERT:
            return __('Cold alert')
        case ALERT_TYPE_HOT_ALERT:
            return __('Hot alert')
        case ALERT_TYPE_CO:
            return __('Co alert')
        case ALERT_TYPE_WENT_OFFLINE:
            return __('Went offline')
        case ALERT_TYPE_GPRS_WENT_OFFLINE:
            return __('GPRS went offline')
        case ALERT_TYPE_BBA_WENT_OFFLINE:
            return __('BBA went offline')
        case ALERT_TYPE_PLINK_WENT_OFFLINE:
            return __('Plink went offline')
        case TROUBLE_TYPE_OPENED:
            return __('Opened')
        case TROUBLE_TYPE_LOW_BATTERY:
            return __('Low battery')
        case TROUBLE_TYPE_INACTIVE:
            return __('Inactive')
        case TROUBLE_TYPE_TAMPER:
            return __('Tamper')
        case TROUBLE_TYPE_TROUBLE:
            return __('Trouble')
        case TROUBLE_TYPE_AC_FAILURE:
            return __('AC failure')
        case TROUBLE_TYPE_NET_TROUBLE:
            return __('Net trouble')
        case TROUBLE_TYPE_ONE_WAY:
            return __('One way')
        case TROUBLE_TYPE_CLEAN_ME:
            return __('Clean me')
        case TROUBLE_TYPE_HEAT_TROUBLE:
            return __('Heat trouble')
        case TROUBLE_TYPE_HEAT_OPEN:
            return __('Heat open')
        case TROUBLE_TYPE_SMOKE_TROUBLE:
            return __('Smoke trouble')
        case TROUBLE_TYPE_SMOKE_OPEN:
            return __('Smoke open')
        case TROUBLE_TYPE_ONE_WAY_REPORTED:
            return __('1 way reported')
        case TROUBLE_TYPE_LINE_FAILURE:
            return __('Line failure')
        case TROUBLE_TYPE_RSSI_LOW:
            return __('Rssi low')
        case TROUBLE_TYPE_MASKING_TROUBLE:
            return __('Masking trouble')
        case TROUBLE_TYPE_JAMMED:
            return __('Jammed')
        case TROUBLE_TYPE_BYPASS:
            return __('Bypass')
        case TROUBLE_TYPE_SOAK_FAILED:
            return __('Soak failed')
        case TROUBLE_TYPE_POOR_OR_LESS_NOW:
            return __('Poor or less now')
        case TROUBLE_TYPE_POOR_OR_LESS_24H:
            return __('Poor or less 24h')
        case TROUBLE_TYPE_PREENROLL_NO_CODE:
            return __('Preenroll')
        case TROUBLE_TYPE_WARM_START:
            return __('Warm Start Trouble')
        case TROUBLE_TYPE_USB_WIFI:
            return __('USB WIFI Trouble')
        case TROUBLE_TYPE_DEVICE_MASK:
            return __('Device Mask Trouble')
        case TROUBLE_TYPE_POWER_UNIT_FAILURE:
            return __('Power Unit Failure')
        case TROUBLE_TYPE_OVERCURRENT:
            return __('Overcurrent trouble')
        case TROUBLE_TYPE_MODULE_BATTERY_2_LOW_VOLTAGE:
            return __('Module Battery 2 Low Voltage Trouble')
        case TROUBLE_TYPE_MODULE_BATTERY_2_MISSING:
            return __('Module Battery 2 Missing Trouble')
        case TROUBLE_TYPE_MODULE_AUX_2:
            return __('Module AUX 2 Trouble')
        case TROUBLE_TYPE_MODULE_BUS_FAULT:
            return __('Module bus fault')
        case TROUBLE_TYPE_PANEL_COMM_FAILURE:
            return __('Communication failure')
        case TROUBLE_TYPE_PANEL_FUSE:
            return __('Fuse')
        case TROUBLE_TYPE_PANEL_NO_ACTIVE:
            return __('No active')
        case TROUBLE_TYPE_PANEL_MARK_FOR_SERVICE:
            return __('Mark for service')
        case TROUBLE_TYPE_PANEL_NOT_DISCOVERED:
            return __('Unfinished discovery')
        case TROUBLE_TYPE_PANEL_SIM_NOT_VERIFIED:
            return __('Sim not verified')
        case TROUBLE_TYPE_PANEL_NOT_ACTIVATED:
            return __('Not activated')
        case TROUBLE_TYPE_MODULE_LOW_BATTERY:
            return __('Module low battery')
        case TROUBLE_TYPE_DEVICE_FAULT:
            return __('Device fault')
        case TROUBLE_TYPE_DEVICE_DELINQUENCY:
            return __('Device delinquency')
        case TROUBLE_TYPE_FAILURE_COMMUNICATE:
            return __('Failure communicate')
        case TROUBLE_TYPE_GSIP_RECEIVER:
            return __('GSIP receiver')
        case TROUBLE_TYPE_ALL_RECEIVER_NOT_AVAILABLE:
            return __('All receiver not available')
        case TROUBLE_TYPE_ALL_RECEIVER_SUPERVISION:
            return __('All receiver supervision')
        case TROUBLE_TYPE_DEVICE_LOW_SENSIVITY:
            return __('Device low sensitivity')
        case TROUBLE_TYPE_DEVICE_INTERNAL_FAULT:
            return __('Device internal fault')
        case TROUBLE_TYPE_RF_DEVICE_NOT_NETWORKED:
            return __('RF device not networked')
        case TROUBLE_TYPE_SERVICE_REQUEST:
            return __('Service request')
        case TROUBLE_TYPE_BELL:
            return __('Bell')
        case TROUBLE_TYPE_FIRE_TROUBLE:
            return __('Fire trouble')
        case TROUBLE_TYPE_CO_TROUBLE:
            return __('CO trouble')
        case TROUBLE_TYPE_GROUND_FAULT:
            return __('Ground fault')
        case TROUBLE_TYPE_OUTPUT_FAULT:
            return __('Output fault')
        case TROUBLE_TYPE_TLM:
            return __('TLM')
        case TROUBLE_TYPE_PRINTER:
            return __('Printer')
        case TROUBLE_TYPE_TIME_DATE:
            return __('Time date')
        case TROUBLE_TYPE_CONFIGURATION:
            return __('Configuration')
        case TROUBLE_TYPE_SIM_LOCK:
            return __('Sim lock')
        case TROUBLE_TYPE_GSM:
            return __('Gsm')
        case TROUBLE_TYPE_ETHERNET:
            return __('Ethernet')
        case TROUBLE_TYPE_MODULE_SUPERVISORY:
            return __('Module supervisory')
        case TROUBLE_TYPE_MODULE_AC:
            return __('Module AC')
        case TROUBLE_TYPE_MODULE_BATTERY_MISSING:
            return __('Module battery missing')
        case TROUBLE_TYPE_MODULE_BATTERY_CHARGER:
            return __('Module battery charger')
        case TROUBLE_TYPE_MODULE_BATTERY_BUS_LOW_VOLTAGE:
            return __(' Module bus low voltage')
        case TROUBLE_TYPE_MODULE_AUX:
            return __('Module AUX')
        case TROUBLE_TYPE_FIRMWARE_UPGRADE:
            return __('Firmware upgrade')
        case TROUBLE_TYPE_DEFAULT_CODE:
            return __('Default code')
        case TROUBLE_TYPE_DUPLICATE_CODE:
            return __('Duplicate code')
        case TROUBLE_TYPE_GAS_TROUBLE:
            return __('Gas trouble')
        case TROUBLE_TYPE_FREEZER:
            return __('Freezer')
        case TROUBLE_TYPE_PROBE:
            return __('Probe')
        case TROUBLE_TYPE_RADIO_SIM:
            return __('Radio sim')
        case TROUBLE_TYPE_MODULE_BATTERY_DISCONNECTED:
            return __('Module battery disconnected')
        case TROUBLE_TYPE_RADIO_LOW_SIGNAL:
            return __('Radio low signal')
        case TROUBLE_TYPE_GSM_NETWORK:
            return __('Gsm network')
        case TROUBLE_TYPE_IP_REMOTE_SERVER:
            return __('Ip remote server')
        case TROUBLE_TYPE_GSM_NETWORK_LOW_INPUT_VOLTAGE:
            return __('Low input voltage')
        case TROUBLE_TYPE_LOW_OUTPUT:
            return __('Low output')
        case TROUBLE_TYPE_MODULE_TAMPER:
            return __('Module tamper')
        case TROUBLE_TYPE_SYSTEM_OVERVIEW:
            return __('System overview')
        case FIBRO_COMPROMISE_ATTEMPT_TROUBLE:
            return __('Compromise attempt')
        case FIBRO_INVALID_REPORT_TROUBLE:
            return __('Invalid report')
        case FIBRO_ENC_DISABLED_BBA_TROUBLE:
        case FIBRO_ENC_DISABLED_BBA:
            return __('Fibro encryption disabled BBA')
        case FIBRO_ENC_DISABLED_GPRS_TROUBLE:
        case FIBRO_ENC_DISABLED_GPRS:
            return __('Fibro encryption disabled GPRS')
        case FIBRO_ENC_NOT_SUPPORTED_BBA_TROUBLE:
        case FIBRO_ENC_NOT_SUPPORTED_BBA:
            return __('Fibro encryption not supported BBA')
        case FIBRO_ENC_NOT_SUPPORTED_GPRS_TROUBLE:
        case FIBRO_ENC_NOT_SUPPORTED_GPRS:
            return __('Fibro encryption not supported GPRS')
        case FIBRO_ENC_FAIL_BBA_TROUBLE:
        case FIBRO_ENC_FAIL_BBA:
            return __('Fibro encryption fail BBA')
        case FIBRO_ENC_FAIL_GPRS_TROUBLE:
        case FIBRO_ENC_FAIL_GPRS:
            return __('Fibro encryption fail GPRS')
        case FIBRO_UNENCRYPTED_BBA:
            return __('Fibro encryption unencrypted BBA')
        case FIBRO_UNENCRYPTED_GPRS:
            return __('Fibro encryption unencrypted GPRS')
    }

    warn(`Unknown trouble ${trouble}`)

    return trouble
}

export function troubleIcon(trouble, alarmIconFallback = false) {
    const icon = icons[trouble]

    if (icon) {
        return icon
    }

    warn(`Unknown icon for trouble ${trouble}`)

    if (alarmIconFallback) {
        return IconGeneralAlert
    }

    return IconGeneralTrouble
}

export function isDeviceOpened(deviceWarnings) {
    if (!Array.isArray(deviceWarnings)) {
        console.error('Wrong warnings format in device, expect array')
        return false
    }

    if (!deviceWarnings.length) {
        return false
    }

    return deviceWarnings.some(({type}) => type === TROUBLE_TYPE_OPENED)
}
