import React from 'react'
import {connect} from 'react-redux'
import {hide} from 'modules/modals/actions'
import {bindActionCreators} from 'redux'
import * as modals from 'modules/modals/constants'

import BlockedNotificationsHelp from './General/BlockedNotificationsHelp'
import About from './General/About'
import AboutLicenseFeatures from './General/AboutLicenseFeatures'
import AboutReleaseNotes from './General/AboutReleaseNotes'
import Confirmation from './General/Confirmation'
import AddPanel from './Panel/AddPanel'
import AddDevice from './Panel/AddDevice'
import EditPanelInfo from './Panel/EditPanelInfo'
import EditPanelCustomerInfo from './Panel/EditPanelCustomerInfo'
import ChangePanelGroup from './Panel/ChangePanelGroup'
import MakeBasicConfiguration from './Panel/MakeBasicConfiguration'
import PushBasicConfiguration from './Panel/PushBasicConfiguration'
import ActivatePanel from './Panel/ActivatePanel'
import MarkForService from './Panel/Service/MarkForService'
import ReassignService from './Panel/Service/ReassignService'
import RebootPlink from './Panel/RebootPlink'
import EnablePlinkLogging from './Panel/EnablePlinkLogging'
import DisablePlinkLogging from './Panel/DisablePlinkLogging'
import AddGroup from './Group/AddGroup'
import EditGroup from './Group/EditGroup'
import FaultsResolve from './Panel/Faults/Resolve'
import FaultsResume from './Panel/Faults/Resume'
import FaultsSuspend from './Panel/Faults/Suspend'
import RemoteInspectionValues from './RemoteInspection/RemoteInspectionValues'
import Schedule from './RemoteInspection/Schedule'
import ScheduleByCsv from './RemoteInspection/ScheduleByCsv'
import CreateReport from './Report/Create'
import EditLinks from './CentralStations/EditLinks'
import AddCentralStation from './CentralStations/AddCentralStation'
import EditCentralStation from './CentralStations/EditCentralStation'
import AddUser from './User/AddUser'
import EditUser from './User/EditUser'
import RemoveUser from './User/RemoveUser'
import EditProfileInfo from './Profile/EditProfileInfo'
import EditSettings from './Profile/EditSettings'
import ChangePassword from './Profile/ChangePassword'
import AddRole from './Roles/AddRole'
import EditRole from './Roles/EditRole'
import RemoveRole from './Roles/RemoveRole'
import EditRolePermissions from './Roles/EditRolePermissions'
import SaveSearchScope from './Profile/SaveSearchScope'
import ShowDeviceMeteoChart from './Panel/ShowDeviceMeteoChart'
import {RenameDevice, RenamePartition} from './Panel/RenameEntity'
import FirmwareUpgrade from './Firmware/Upgrade'
import UnavailableFeature from './Panel/UnavailableFeature'
import TriggerDiscovery from './Panel/TriggerDiscovery'

import MessageBrokerSettings from './System/Settings/MessageBrokerSettings'
import CellularConnectedSettings from './System/Settings/CellularConnectedSettings'
import BroadbandConnectedSettings from './System/Settings/BroadbandConnectedSettings'
import CommonConnectedSettings from './System/Settings/CommonConnectedSettings'
import SupervisionSettings from './System/Settings/SupervisionSettings'
import RemoteInspectionSettings from './System/Settings/RemoteInspectionSettings'
import AdvertisementSettings from './System/Settings/AdvertisementSettings'
import InteractiveSessionSettings from './System/Settings/InteractiveSessionSettings'
import UserNotificationsSettings from './System/Settings/UserNotificationsSettings'
import AuthorizationSettings from './System/Settings/AuthorizationSettings'
import CustomerInfoSettings from './System/Settings/CustomerInfoSettings'
import RotationSettings from './System/Settings/RotationSettings'
import AssignUserToPanel from './InteractiveUsers/AssignUserToPanel'
import RegisterInteractiveUser from './InteractiveUsers/RegisterInteractiveUser'

const components = {
    [modals.MODAL_ABOUT]: About,
    [modals.MODAL_ABOUT_LICENSE_FEATURES]: AboutLicenseFeatures,
    [modals.MODAL_ABOUT_RELEASE_NOTES]: AboutReleaseNotes,
    [modals.MODAL_BLOCKED_NOTIFICATIONS_HELP]: BlockedNotificationsHelp,
    [modals.MODAL_CONFIRMATION]: Confirmation,
    [modals.MODAL_MARK_FOR_SERVICE]: MarkForService,
    [modals.MODAL_REASSIGN_SERVICE]: ReassignService,
    [modals.MODAL_CHANGE_PANEL_GROUP]: ChangePanelGroup,
    [modals.MODAL_ADD_PANEL]: AddPanel,
    [modals.MODAL_EDIT_PANEL_INFO]: EditPanelInfo,
    [modals.MODAL_EDIT_PANEL_CUSTOMER_INFO]: EditPanelCustomerInfo,
    [modals.MODAL_MAKE_BASIC_CONFIGURATION]: MakeBasicConfiguration,
    [modals.MODAL_PUSH_BASIC_CONFIGURATION]: PushBasicConfiguration,
    [modals.MODAL_ACTIVATE_PANEL]: ActivatePanel,
    [modals.MODAL_ADD_GROUP]: AddGroup,
    [modals.MODAL_EDIT_GROUP]: EditGroup,
    [modals.MODAL_CREATE_REPORT]: CreateReport,
    [modals.MODAL_FAULTS_RESOLVE]: FaultsResolve,
    [modals.MODAL_FAULTS_SUSPEND]: FaultsSuspend,
    [modals.MODAL_FAULTS_RESUME]: FaultsResume,
    [modals.MODAL_SCHEDULE_RRI]: Schedule,
    [modals.MODAL_SCHEDULE_RRI_CSV]: ScheduleByCsv,
    [modals.MODAL_REMOTE_INSPECTION_VALUES]: RemoteInspectionValues,
    [modals.MODAL_EDIT_CENTRAL_STATION_LINKS]: EditLinks,
    [modals.MODAL_ADD_CENTRAL_STATION]: AddCentralStation,
    [modals.MODAL_EDIT_CENTRAL_STATION]: EditCentralStation,
    [modals.MODAL_ADD_USER]: AddUser,
    [modals.MODAL_EDIT_USER]: EditUser,
    [modals.MODAL_REMOVE_USER]: RemoveUser,
    [modals.MODAL_ADD_DEVICE]: AddDevice,
    [modals.MODAL_EDIT_PROFILE_INFO]: EditProfileInfo,
    [modals.MODAL_EDIT_SETTINGS]: EditSettings,
    [modals.MODAL_CHANGE_PASSWORD]: ChangePassword,
    [modals.MODAL_REBOOT_PLINK]: RebootPlink,
    [modals.MODAL_ENABLE_PLINK_LOGGING]: EnablePlinkLogging,
    [modals.MODAL_DISABLE_PLINK_LOGGING]: DisablePlinkLogging,
    [modals.MODAL_ADD_ROLE]: AddRole,
    [modals.MODAL_EDIT_ROLE]: EditRole,
    [modals.MODAL_REMOVE_ROLE]: RemoveRole,
    [modals.MODAL_EDIT_ROLE_PERMISSIONS]: EditRolePermissions,
    [modals.MODAL_SAVE_SEARCH_SCOPE]: SaveSearchScope,
    [modals.MODAL_SHOW_DEVICE_METEO_CHART]: ShowDeviceMeteoChart,
    [modals.MODAL_RENAME_DEVICE]: RenameDevice,
    [modals.MODAL_RENAME_PARTITION]: RenamePartition,
    [modals.MODAL_FIRMWARE_UPGRADE]: FirmwareUpgrade,
    [modals.MODAL_TRIGGER_DISCOVERY]: TriggerDiscovery,
    [modals.MODAL_UNAVAILABLE_FEATURE]: UnavailableFeature,

    [modals.MODAL_SYSTEM_SETTINGS_CELLULAR_CONNECTED]: CellularConnectedSettings,
    [modals.MODAL_SYSTEM_SETTINGS_BROADBAND_CONNECTED]: BroadbandConnectedSettings,
    [modals.MODAL_SYSTEM_SETTINGS_COMMON_CONNECTED]: CommonConnectedSettings,
    [modals.MODAL_SYSTEM_SETTINGS_SUPERVISION]: SupervisionSettings,
    [modals.MODAL_SYSTEM_SETTINGS_REMOTE_INSPECTION]: RemoteInspectionSettings,
    [modals.MODAL_SYSTEM_SETTINGS_INTERACTIVE_SESSION]: InteractiveSessionSettings,
    [modals.MODAL_SYSTEM_SETTINGS_USER_NOTIFICATIONS]: UserNotificationsSettings,
    [modals.MODAL_SYSTEM_SETTINGS_ADVERTISEMENT]: AdvertisementSettings,
    [modals.MODAL_SYSTEM_SETTINGS_MESSAGE_BROKER]: MessageBrokerSettings,
    [modals.MODAL_SYSTEM_SETTINGS_AUTHORIZATION_SETTINGS]: AuthorizationSettings,
    [modals.MODAL_SYSTEM_SETTINGS_CUSTOMER_INFO]: CustomerInfoSettings,
    [modals.MODAL_SYSTEM_SETTINGS_ROTATION]: RotationSettings,

    [modals.MODAL_ASSIGN_USER_TO_PANEL]: AssignUserToPanel,
    [modals.MODAL_REGISTER_INTERACTIVE_USER]: RegisterInteractiveUser,
}

export const ModalsContainer = ({type, hide, args}) => {
    if (!type) {
        return null
    }

    if (!components[type]) {
        throw new Error`Unknown modal type ${type}`()
    }

    const Component = components[type]

    return <Component {...args} hide={hide} />
}

export default connect(
    ({modals}) => ({...modals}),
    (dispatch) => bindActionCreators({hide}, dispatch)
)(ModalsContainer)
