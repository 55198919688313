import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import list from 'permissions/processes/list'
import processType from 'constants/processType'

import {
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
    setQuery,
} from 'modules/processes/list/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Search from 'components/Search/Search'
import DateFilter from 'components/Search/Filters/DateFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'

import __ from 'utils/i18n'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new SuggestFilter('serial', __('Panel Id')),
            new TranslateSuggestFilter('type', __('Type'), processType),
            new SuggestFilter('user', __('User')),
            new TranslateSuggestFilter('status', __('Status'), {
                succeeded: __('Succeeded'),
                failed: __('Failed'),
            }),
            new DateFilter('started', __('Started at')),
        ],
    })),
    connect(
        ({processes}) => ({
            selected: processes.list.filters,
            suggests: processes.list.suggests,
            query: processes.list.query,
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    apply: fetch,
                    onSelect: addFilters,
                    onDeselect: removeFilters,
                    onClear: clearFilters,
                    onSuggest: fetchSuggests,
                    onQuery: setQuery,
                },
                dispatch
            )
    )
)(Search)
