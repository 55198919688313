import {takeEvery, all, put, select, call} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/interactiveUsers'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        takeEvery(actions.disconnectInteractiveUser, watchDisconnectInteractiveUser),
        takeEvery(actions.enable, watchEnable),
        takeEvery(actions.suspend, watchSuspend),
    ])
}

function* watchDisconnectInteractiveUser({payload: id, meta: {panelId}}) {
    const powerUserId = yield select(
        (state) => state.panelInteractiveUsers.store[panelId][id].powerUserId
    )

    try {
        const interactiveUser = yield call(api.disconnectPanel, {
            powerUserId,
            panelId,
        })

        yield put(actions.update(interactiveUser, panelId))
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.revertDisconnectInteractiveUser(id, panelId))
    }
}

function* watchEnable({payload: interactiveUserId, meta: {panelId}}) {
    const user = yield select(
        (state) => state.panelInteractiveUsers.store[panelId][interactiveUserId]
    )

    try {
        const {status} = yield call(api.enable, user.powerUserId)
        yield put(actions.update({...user, status}, panelId))
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.revert(interactiveUserId, panelId))
    }
}

function* watchSuspend({payload: interactiveUserId, meta: {panelId}}) {
    const user = yield select(
        (state) => state.panelInteractiveUsers.store[panelId][interactiveUserId]
    )

    try {
        const {status} = yield call(api.suspend, user.powerUserId)
        yield put(actions.update({...user, status}, panelId))
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.revert(interactiveUserId, panelId))
    }
}
