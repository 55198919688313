import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    setQuery,
} from 'modules/installers/list/actions'

import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import Search from 'components/Search/Search'

import __ from 'utils/i18n'

export default compose(
    connect(
        (state) => ({
            filters: [
                new ValuesFilter('status', __('Status'), {
                    pending: __('Pending'),
                    accepted: __('Accepted'),
                    rejected: __('Rejected'),
                }),
            ],
            query: state.installers.list.query,
            selected: state.installers.list.filters,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    apply: fetch,
                    onQuery: setQuery,
                    onSelect: addFilters,
                    onClear: clearFilters,
                    onDeselect: removeFilters,
                },
                dispatch
            )
    )
)(Search)
