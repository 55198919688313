import DiagnosticInfo from 'components/Diagnostic/DiagnosticInfo'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {deviceSignals} from 'constants/signalLevelType'
import {signalLevelTitle} from 'constants/signalLevelType'
import {DEVICE_TYPE_PGM, DEVICE_TYPE_POWER_LINK} from 'constants/deviceType'

import plink from 'permissions/panel/devices/plink'
import {soak, rarelyUsed, bypass, remove} from 'permissions/panel/devices/actions'
import {setLabel} from 'permissions/panel/labels'
import diagnostic from 'permissions/panel/devices/diagnostic'

import {showRenameDeviceModal} from 'modules/modals/actions'
import {
    setBypass,
    setRarelyUsed,
    setSoak,
    removeDevice,
} from 'modules/devices/list/actions'

import {withPermission} from 'containers/withPermission'
import withConfirmation from 'containers/withConfirmation'

import List, {ListHintItem} from 'ipmp-react-ui/List'
import Checkbox from 'ipmp-react-ui/Checkbox'
import Button from 'ipmp-react-ui/Button'
import Definition from 'ipmp-react-ui/Definition'

import PlinkDebug from './PlinkDebug'

import PGMForm from './PGM/EditPGM'
import RefreshRssiButton from 'pages/Panel/Devices/Bars/Buttons/RefreshRssiButton'

import {humanTime} from 'ipmp-react-ui/humanTime'
import {__} from 'utils/i18n'

const ConfirmationButton = withConfirmation()(Button)

export class GeneralInfoTab extends Component {
    static propTypes = {
        device: PropTypes.shape({
            traits: PropTypes.shape({
                rarely_used: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool.isRequired,
                }),
                bypass: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool.isRequired,
                }),
                soak: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool.isRequired,
                }),
                rssi: PropTypes.shape({
                    average: PropTypes.oneOf(deviceSignals),
                    current: PropTypes.oneOf(deviceSignals),
                    channel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                    repeater: PropTypes.number,
                    triggeredAt: PropTypes.string,
                }),
            }),
        }).isRequired,
        onSetBypass: PropTypes.func.isRequired,
        onSetSoak: PropTypes.func.isRequired,
        onSetRarelyUsed: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        onRename: PropTypes.func.isRequired,
        isBypassAllowed: PropTypes.bool,
        isSoakAllowed: PropTypes.bool,
        isRarelyUsedAllowed: PropTypes.bool,
        isRemoveAllowed: PropTypes.bool,
        isRenameDevices: PropTypes.bool,
    }

    static defaultProps = {
        isBypassAllowed: true,
        isSoakAllowed: true,
        isRarelyUsedAllowed: true,
        isRemoveAllowed: true,
    }

    setBypass = (e) => {
        this.props.onSetBypass(e.target.checked)
    }

    setSoak = (e) => {
        this.props.onSetSoak(e.target.checked)
    }

    setRarelyUsed = (e) => {
        this.props.onSetRarelyUsed(e.target.checked)
    }

    remove = (e) => {
        this.props.onRemove()
    }

    rename = (e) => {
        this.props.onRename()
    }

    renderRssi({average, current, channel, repeater, last_updated: updated}) {
        const empty = <span className="empty">{__('not set')}</span>

        const info = (
            <List twoCols>
                <ListHintItem
                    name={__('Current')}
                    text={current ? signalLevelTitle(current) : empty}
                />
                <ListHintItem
                    name={__('Average')}
                    text={average ? signalLevelTitle(average) : empty}
                />
                <ListHintItem name={__('Channels')} text={channel || empty} />
                <ListHintItem
                    name={__('Repeater')}
                    text={repeater !== null ? repeater : empty}
                />
            </List>
        )

        return (
            <div className="block rssi">
                <Definition
                    className="title"
                    multiLine
                    title={__('RSSI')}
                    detail={updated && humanTime(updated)}
                />

                {updated ? (
                    info
                ) : (
                    <div className="rssi-noMeasurements">
                        <h6 className="title title--medium">{__('No measurements')}</h6>
                        <p className="rssi-noMeasurements-content">
                            {__('Click Refresh RSSI button to reveal device rssi info')}
                        </p>
                        <RefreshRssiButton flat />
                    </div>
                )}
            </div>
        )
    }

    renderDiagnostic(diagnostic) {
        return (
            <div className="block">
                <h4 className="title">{__('Diagnostic')}</h4>

                <DiagnosticInfo {...diagnostic} />
            </div>
        )
    }

    renderPGM() {
        const {panelId, device} = this.props

        return (
            <div className="block">
                <PGMForm
                    panelId={panelId}
                    id={device.id}
                    partitions={device.partitions}
                />
            </div>
        )
    }

    renderMain() {
        const {
            isBypassAllowed,
            isSoakAllowed,
            isRarelyUsedAllowed,
            isRemoveAllowed,
            isRenameDevices,
        } = this.props
        const {traits, isRemovable, isRenameable} = this.props.device || {}
        const {rarely_used: rarelyused, soak, bypass} = traits || {}

        const bypassCheckbox = bypass && (
            <Checkbox
                disabled={!isBypassAllowed}
                onChange={this.setBypass}
                label={__('Bypass')}
                progress={bypass.progress}
                checked={bypass.enabled}
            />
        )

        const soakCheckbox = soak && (
            <Checkbox
                disabled={!isSoakAllowed}
                onChange={this.setSoak}
                label={__('Soak')}
                progress={soak.progress}
                checked={soak.enabled}
            />
        )

        const rarelyUsedCheckbox = rarelyused && (
            <Checkbox
                disabled={!isRarelyUsedAllowed}
                onChange={this.setRarelyUsed}
                label={__('Rarely Triggered')}
                progress={rarelyused.progress}
                checked={rarelyused.enabled}
            />
        )

        const renameButton = isRenameDevices && isRenameable && (
            <Button
                flat
                className="devices-sidebar-button"
                onClick={this.rename}
                label={__('Rename')}
            />
        )

        const removeButton = isRemoveAllowed && isRemovable && (
            <ConfirmationButton
                flat
                className="devices-sidebar-button"
                onClick={this.remove}
                title={__('Remove device')}
                message={__('Do you really want to remove device')}
                positive={__('Remove')}
                label={__('Remove')}
            />
        )

        if (
            bypassCheckbox ||
            soakCheckbox ||
            rarelyUsedCheckbox ||
            renameButton ||
            removeButton
        ) {
            return (
                <div className="block">
                    {bypassCheckbox}
                    {soakCheckbox}
                    {rarelyUsedCheckbox}
                    {(renameButton || removeButton) && (
                        <div className="blindModalDevices-actions">
                            {renameButton}
                            {removeButton}
                        </div>
                    )}
                </div>
            )
        }

        return null
    }

    render() {
        const {panelId, isPlinkAllowed, isDiagnosticAllowed} = this.props
        const {traits, deviceType} = this.props.device || {}
        const {rssi, diagnostic} = traits || {}

        return (
            <div className="blocks">
                {this.renderMain()}
                {rssi && this.renderRssi(rssi)}
                {isDiagnosticAllowed && diagnostic && this.renderDiagnostic(diagnostic)}
                {isPlinkAllowed && deviceType === DEVICE_TYPE_POWER_LINK && (
                    <PlinkDebug panelId={panelId} />
                )}

                {
                    /* PMN-5963: temporary hide PGM Form */
                    false && deviceType === DEVICE_TYPE_PGM && this.renderPGM()
                }
            </div>
        )
    }
}

export default compose(
    withPermission({
        isPlinkAllowed: plink,
        isBypassAllowed: bypass,
        isSoakAllowed: soak,
        isRarelyUsedAllowed: rarelyUsed,
        isRemoveAllowed: remove,
        isDiagnosticAllowed: diagnostic,
        isRenameDevices: setLabel,
    }),
    connect(null, (dispatch, {panelId, device}) =>
        bindActionCreators(
            {
                onSetBypass: (enabled) => setBypass(panelId, {[device.id]: enabled}),
                onSetSoak: (enabled) => setSoak(panelId, device.id, enabled),
                onSetRarelyUsed: (enabled) => setRarelyUsed(panelId, device.id, enabled),
                onRemove: () => removeDevice(panelId, device.id),
                onRename: () => showRenameDeviceModal(panelId, device),
            },
            dispatch
        )
    )
)(GeneralInfoTab)
