import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Fab from 'ipmp-react-ui/Fab'
import {ReactComponent as IconClose} from 'ipmp-react-ui/icons/cross.svg'

import processType, {getProcessLinkByType} from 'constants/processType'
import path from 'utils/path'
import BatchRunnerItem from './BatchRunnerItem'
import BatchRunnerStatus from './BatchRunnerStatus'
import __ from 'utils/i18n'
import Error from 'ipmp-react-ui/Error'
import Button from 'ipmp-react-ui/Button'
import Spinner from 'ipmp-react-ui/Spinner'

export default class BatchInfo extends Component {
    static propTypes = {
        batch: PropTypes.shape({
            type: PropTypes.string,
        }),
        stoppingIds: PropTypes.array,
        runners: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                panelId: PropTypes.number.isRequired,
            })
        ),
        stop: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,
        batchInfoRefHandler: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        error: PropTypes.object,
        hasMore: PropTypes.bool,
    }

    onClose = (e) => {
        const {hide} = this.props

        hide()
    }

    onStop = this.props.stop

    handleClick = ({panelId}) => {
        const {history, batch, hide} = this.props
        const link = getProcessLinkByType(batch.type)

        if (!link) {
            return
        }

        history.push(path(link, {id: panelId}))
        hide()
    }

    onMoreClick = () => {
        const {history, batch, hide} = this.props

        history.push(path('batches.runners', {id: batch.id}))
        hide()
    }

    renderBatchItem = (runner, isStopping) => (
        <BatchRunnerItem {...runner} key={runner.id} onRowClick={this.handleClick}>
            <BatchRunnerStatus
                row={{
                    ...runner,
                }}
                isStopping={isStopping}
                isStopPermitted={this.props.isStopPermitted}
                onStop={this.onStop}
            />
        </BatchRunnerItem>
    )

    renderList = () => {
        const {runners, isLoading, error, stoppingIds} = this.props
        if (isLoading) {
            return <Spinner />
        }

        if (error) {
            return <Error error={error} />
        }

        if (!runners.length) {
            return <Error message={__('No processes')} />
        }
        return runners.map((runner) => {
            return runner && this.renderBatchItem(runner, stoppingIds.includes(runner.id))
        })
    }

    render() {
        const {hasMore, batch, batchInfoRefHandler} = this.props
        const {type} = batch

        return (
            <section className="batchInfo" ref={batchInfoRefHandler}>
                <header className="batchInfo-title">
                    {processType(type)}
                    <Fab
                        mini
                        borderless
                        className="batchInfo-close"
                        Icon={IconClose}
                        onClick={this.onClose}
                    />
                </header>

                <div className="batchInfo-list">
                    {this.renderList()}

                    {hasMore && (
                        <div className="batchInfo-list-moreBtnHolder">
                            <Button
                                primary
                                onClick={this.onMoreClick}
                                label={__('More')}
                            />
                        </div>
                    )}
                </div>
            </section>
        )
    }
}
