import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {
    select,
    selectAll,
    deselect,
    deselectAll,
    reverse,
} from 'modules/selection/actions'
import getHocName from 'utils/getHocName'

export default function withSelection() {
    return connect(
        (state) => ({
            selection: state.selection,
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    select,
                    selectAll,
                    deselect,
                    deselectAll,
                    reverse,
                },
                dispatch
            )
    )
}

/**
 * @deprecated
 * Use filter in `withSelectionHandler`. It will filter onClick array as well
 */
export function ifSelection(filter: (id: number, state: Object) => Boolean) {
    return (WrappedComponent) => {
        const fn = ({isHidden, ...props}) =>
            isHidden ? null : <WrappedComponent {...props} />

        fn.displayName = getHocName('ifSelection', WrappedComponent)

        return connect(
            (state) => {
                const visible = filter
                    ? state.selection.some((id) => filter(id, state))
                    : !state.selection.isEmpty()

                return {
                    isHidden: !visible,
                }
            },
            () => ({})
        )(fn)
    }
}

export function withSelectionHandler(filter: (id: number, state: Object) => Boolean) {
    return (WrappedComponent) =>
        connect((state) => {
            const selection = filter
                ? state.selection.toJS().filter((item) => filter(item, state))
                : state.selection.toJS()

            return {
                selection,
            }
        })(
            class extends React.Component {
                static displayName = getHocName('WithSelectionHandler', WrappedComponent)

                onClick = () => {
                    this.props.onClick && this.props.onClick(this.props.selection)
                }

                shouldComponentUpdate(nextProps, nextState, nextContext) {
                    const {selection} = this.props

                    const wasVisible = !!selection.length
                    const shouldVisible = !!nextProps.selection.length

                    return wasVisible !== shouldVisible
                }

                render() {
                    const {selection, ...props} = this.props

                    if (!selection.length) {
                        return null
                    }

                    return <WrappedComponent {...props} onClick={this.onClick} />
                }
            }
        )
}
