import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Card, {CardHeader, CardContent} from 'ipmp-react-ui/Card'
import Hotkey from 'ipmp-react-ui/Hotkey'

import SearchBar from 'components/Search/SearchBar'
import InteractiveUserAddPanelTable from './InteractiveUserAddPanelTable'
import InteractiveUserAddPanelPager from './InteractiveUserAddPanelPager'

import __ from 'utils/i18n'
import {withInteractiveUserAddPanelTable} from 'containers/withInteractiveUserPanelsTable'

class InteractiveUserAddPanel extends Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        interactiveUser: PropTypes.shape({
            email: PropTypes.string,
        }).isRequired,
        setQuery: PropTypes.func.isRequired,
        fetch: PropTypes.func.isRequired,
    }

    handleSearchChange = (value) => this.props.setQuery(value)

    applySearch = () => this.props.fetch()

    onClose = () => this.props.onClose()

    render() {
        const {interactiveUser} = this.props

        return (
            <Card className="interactiveUserAddPanel">
                <CardHeader onBack={this.onClose}>
                    {__('Add panel to %s', interactiveUser.email)}
                </CardHeader>
                <CardContent>
                    <Bar>
                        <SearchBar
                            onChange={this.handleSearchChange}
                            placeholder={__('Quick search')}
                        />
                        <Hotkey
                            shortcut="enter"
                            action={this.applySearch}
                            scope="input"
                        />

                        <BarSpace />

                        <InteractiveUserAddPanelPager />
                    </Bar>
                    <InteractiveUserAddPanelTable />
                </CardContent>
            </Card>
        )
    }
}

export default compose(withInteractiveUserAddPanelTable())(InteractiveUserAddPanel)
