import {takeEvery, all, put, select, call} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/panels'
import {sortFaults} from 'api/panels.map'

import {
    PROCESS_TYPE_PMAXCONFIGUPLOAD,
    PROCESS_TYPE_PMAXSTATEGET,
    PROCESS_TYPE_PANEL_DISCOVERY,
    PROCESS_TYPE_NEO_DIAGNOSTIC,
} from 'constants/processTypes'
import {DEVICE_TYPE_CONTROL_PANEL} from 'constants/deviceType'
import {SEVERITY_TROUBLE} from 'constants/severityType'
import {TROUBLE_TYPE_PANEL_MARK_FOR_SERVICE} from 'constants/troubleType'

import {
    changeGroup,
    editPanelInfo,
    editPanelCustomerInfo,
    suspendFaults,
    resumeFaults,
    resolveFaults,
    markForService,
    reassignService,
} from 'modules/forms/handlers'

import {fetch} from 'modules/panels/one/actions'
import generateBatch from 'modules/batches/manager/generateBatch'
import {snackShow} from 'modules/snacks'

import {__} from 'utils/i18n'
import {ensureProcesses} from 'modules/processes/manager/ensureProcess'

export default function* () {
    yield all([
        takeEvery(actions.refreshState, watchRefreshState),
        takeEvery(actions.refresh, watchRefresh),
        takeEvery(changeGroup.SUCCESS, watchChangeGroup),
        takeEvery(editPanelInfo.SUCCESS, watchEditPanelInfo),
        takeEvery(editPanelCustomerInfo.SUCCESS, watchEditPanelCustomerInfo),
        takeEvery(suspendFaults.SUCCESS, watchSuspendFaults),
        takeEvery(markForService.SUCCESS, watchMarkForService),
        takeEvery(reassignService.SUCCESS, watchReassignService),
        takeEvery(resumeFaults.SUCCESS, watchResumeFaults),
        takeEvery(resolveFaults.SUCCESS, watchResolveFaults),
        takeEvery(actions.pushBasicConfiguration, watchPushBasicConfiguration),
        takeEvery(actions.discovery, watchDiscovery),
        takeEvery(actions.readDiagnostic, watchReadDiagnostic),
        takeEvery(actions.setEncryptionState, watchEncryptionState),
    ])
}

function* watchEditPanelInfo({meta}) {
    const id = meta.panelId
    const {type, ...data} = meta.data
    const panel = yield select(({panels}) => panels.store.byIds[id] || {})

    const modules = Object.keys(panel.modules).reduce(
        (acc, key) => ({
            ...acc,
            key: type.includes(key) ? panel.modules[key] || 'offline' : false,
        }),
        {}
    )

    yield put(
        actions.update({
            id,
            ...data,
            modules,
        })
    )

    yield put(fetch(id))
}

function* watchEditPanelCustomerInfo({meta}) {
    const {panelId, data} = meta

    yield put(
        actions.update({
            id: panelId,
            contact: data,
        })
    )
}

function* watchChangeGroup({meta}) {
    const {ids, groupId} = meta
    const group = yield select((state) => state.groups.store.byIds[groupId])
    yield put(actions.update(ids.map((id) => ({id, group: group.name}))))
}

function* watchRefreshState({payload: panelIds}) {
    try {
        const {batchId} = yield generateBatch(PROCESS_TYPE_PMAXSTATEGET, panelIds)
        yield call(api.refreshState, panelIds, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchRefresh({payload: panelIds}) {
    try {
        yield put(actions.setRefreshing())
        const data = yield call(api.fetch, {filters: {id: panelIds}})
        yield put(actions.update(data.rows))
        yield put(actions.setRefreshing(false))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function updatePanelFaults(panels, faultIds, patch) {
    const updater =
        typeof patch === 'function'
            ? patch
            : (faults) =>
                  faults.map((fault) => {
                      if (!faultIds.includes(fault.id)) {
                          return fault
                      }

                      return {
                          ...fault,
                          ...patch,
                      }
                  })

    return Object.values(panels).reduce((acc, panel) => {
        if (
            !panel.faults ||
            panel.faults.every((fault) => !faultIds.includes(fault.id))
        ) {
            return acc
        }

        acc.push({
            id: panel.id,
            faults: sortFaults(updater(panel.faults)),
        })

        return acc
    }, [])
}

function* watchSuspendFaults({meta: {faultIds}}) {
    const panels = yield select((state) => state.panels.store.byIds)
    const panelsPatch = updatePanelFaults(panels, faultIds, {isSuspended: true})

    if (panelsPatch.length > 0) {
        yield put(actions.update(panelsPatch))
    }
}

function* watchResumeFaults({meta: {faultIds}}) {
    const panels = yield select((state) => state.panels.store.byIds)
    const panelsPatch = updatePanelFaults(panels, faultIds, {isSuspended: false})

    if (panelsPatch.length > 0) {
        yield put(actions.update(panelsPatch))
    }
}

function* watchResolveFaults({meta: {faultIds}}) {
    const panels = yield select((state) => state.panels.store.byIds)
    const panelsPatch = updatePanelFaults(panels, faultIds, (faults) =>
        faults.filter((fault) => !faultIds.includes(fault.id))
    )

    if (panelsPatch.length > 0) {
        yield put(actions.update(panelsPatch))
    }
}

function* watchMarkForService({meta: {ids, comment, userId, user}, payload: {result}}) {
    const byIds = yield select((state) => state.panels.store.byIds)

    const fault = {
        severity: SEVERITY_TROUBLE,
        type: TROUBLE_TYPE_PANEL_MARK_FOR_SERVICE,
        deviceType: DEVICE_TYPE_CONTROL_PANEL,
        isMarkForService: true,
        isSuspended: false,
        zone: null,
        comment,
    }

    const panels = ids
        .map((id) => byIds[id])
        .filter((panel) => panel)
        .map(({id, faults}) => ({
            id,
            user,
            userId,
            faults: sortFaults(
                (faults || []).concat({
                    id: result[id],
                    ...fault,
                })
            ),
        }))

    if (panels.length > 0) {
        yield put(actions.update(panels))
    }
}

function* watchReassignService({meta: {ids, userId, user}}) {
    const panels = ids.map((id) => ({
        id,
        user,
        userId,
    }))

    if (panels.length > 0) {
        yield put(actions.update(panels))
    }
}

function* watchPushBasicConfiguration({payload: {basicConfigId, panelIds}}) {
    const {batchId} = yield generateBatch(PROCESS_TYPE_PMAXCONFIGUPLOAD, panelIds)

    try {
        yield call(api.pushBasicConfiguration, panelIds, basicConfigId, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchDiscovery({payload: {panelIds, timeout}}) {
    const {batchId} = yield generateBatch(PROCESS_TYPE_PANEL_DISCOVERY, panelIds)

    try {
        yield api.discovery(panelIds, timeout, batchId)
        yield put(snackShow(__('Discovery triggered')))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchReadDiagnostic({payload: {panelId}}) {
    const {batchId} = yield generateBatch(PROCESS_TYPE_NEO_DIAGNOSTIC, [panelId])

    try {
        const {processes} = yield api.readDiagnostic(panelId, batchId)
        yield ensureProcesses(processes)
        yield put(snackShow(__('Read Diagnostic')))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchEncryptionState({payload}) {
    const {id, channel, encrypted} = payload

    try {
        yield call(api.setEncryption, id, channel, encrypted)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
