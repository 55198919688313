import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Button from 'ipmp-react-ui/Button'
import Card, {CardActions, CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import Logo from 'ui/Logo'
import {__} from 'utils/i18n'
import timezones, {getTz} from 'utils/timezones'
import Modal from 'ipmp-react-ui/Modal'

import ReleaseNotes from './ui/ReleaseNotes'
import LicenseInfo from './ui/LicenseInfo'

export default class About extends Component {
    static propTypes = {
        hide: PropTypes.func.isRequired,
    }

    handler = null

    state = {
        time: timezones.server(),
    }

    componentDidMount() {
        this.handler = setInterval(() => {
            this.setState({
                time: timezones.server(),
            })
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.handler)
    }

    render() {
        const {hide} = this.props

        return (
            <Modal onClose={hide}>
                <Card className="about">
                    <CardClose onClick={hide} />

                    <CardHeader>
                        <Logo multiline />
                    </CardHeader>

                    <CardContent>
                        <div className="about-info">
                            <p className="hint hint--gray">{__('Version')}</p>
                            {process.env.REACT_APP_IPMP_VERSION}&nbsp;
                            <ReleaseNotes />
                        </div>

                        <div className="about-info">
                            <p className="hint hint--gray">{__('Build number')}</p>
                            {process.env.REACT_APP_IPMP_REVISION}
                        </div>

                        <div className="about-info">
                            <p className="hint hint--gray">{__('Server time')}</p>
                            {this.state.time.format('LL – LT')}
                        </div>

                        <div className="about-info">
                            <p className="hint hint--gray">{__('Server timezone')}</p>
                            {getTz()} {timezones.server().format('Z z')}
                        </div>
                        <LicenseInfo />
                    </CardContent>

                    <CardActions>
                        <Button primary onClick={hide}>
                            {__('Ok')}
                        </Button>
                    </CardActions>
                </Card>
            </Modal>
        )
    }
}
