import createListReducer, {
    generalDefaultState,
} from 'modules/higherOrder/createListReducer'
import * as actions from './actions'

const defaultState = {
    interactiveUserId: null,
}

export default createListReducer(
    actions,
    {
        [actions.fetch]: (state, {payload: {interactiveUserId}}) => ({
            ...state,
            interactiveUserId: interactiveUserId || state.interactiveUserId,
        }),
        [actions.disconnectPanel]: (state, {payload: panelId}) => {
            return {
                ...state,
                oldPage: state.page,
                oldTotal: state.total,
                total: state.total && state.total - 1,
                page: state.page.filter((id) => id !== panelId),
            }
        },
        [actions.resetWithOutFetch]: () => {
            return {
                ...generalDefaultState,
                ...defaultState,
            }
        },
    },
    defaultState
)
