import __ from 'utils/i18n'
import {warn} from 'utils/log'

export const MODULE_TYPE_GPRS = 'gprs'
export const MODULE_TYPE_BBA = 'bba'
export const MODULE_TYPE_WIFI = 'wifi'
export const MODULE_TYPE_BBA_OVER_GPRS = 'bba_over_gprs'

export const MODULE_STATE_ONLINE = 'online'
export const MODULE_STATE_OFFLINE = 'offline'
export const MODULE_STATE_INSTALLED = 'installed'
export const MODULE_STATE_UNSTABLE = 'unstable'
export const MODULE_STATE_CONFIGURED = 'configured'

export const MODULE_STATES = [
    MODULE_STATE_ONLINE,
    MODULE_STATE_OFFLINE,
    MODULE_STATE_INSTALLED,
    MODULE_STATE_CONFIGURED,
]

export const MODULE_STATES_HASH = MODULE_STATES.reduce((acc, state) => {
    acc[state] = moduleTitle(state)
    return acc
}, {})

export default function moduleTitle(moduleState) {
    switch (moduleState) {
        case MODULE_STATE_ONLINE:
            return __('Online')
        case MODULE_STATE_OFFLINE:
            return __('Offline')
        case MODULE_STATE_INSTALLED:
            return __('Installed')
        case MODULE_STATE_UNSTABLE:
            return __('Unstable')
        case MODULE_STATE_CONFIGURED:
            return __('Configured')
    }

    warn(`Unknown module state ${moduleState}`)
    return module.state || __('Unknown')
}

export function moduleTypeTitle(type) {
    switch (type) {
        case MODULE_TYPE_GPRS:
            return __('GPRS Encryption')
        case MODULE_TYPE_BBA:
            return __('BBA Encryption')
        default:
            warn(`Unknown module type: ${type}`)
            return type
    }
}
