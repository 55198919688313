import {createSelector} from 'reselect'

export const selectInteractiveUserAddPanelList = createSelector(
    (state) => state.interactiveUserPanels.addPanelList,
    (state) => state.panels.store.byIds,
    ({page, start, total, error, isLoading}, store) => ({
        rows: page.map((id) => store[id] || {}),
        isLoading,
        start,
        total,
        error,
    })
)
