import {handleActions} from 'redux-actions'

import {remember, forget} from './actions'

const defaultState = false

export default handleActions(
    {
        [forget]() {
            return defaultState
        },

        [remember](state, {payload: {id, to, serial}}) {
            return {
                id,
                to,
                serial,
            }
        },
    },
    defaultState
)
