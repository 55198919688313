import {takeEvery, put, select} from 'redux-saga/effects'
import {remember} from './actions'
import {purgeStore} from 'modules/panels/one/actions'

export default function* () {
    yield takeEvery(remember, watchRemember)
}

function* watchRemember({payload}) {
    const newPanelId = payload.id
    const currentPanelId = yield select((state) => state.panels.recent.id)

    if (newPanelId !== currentPanelId) {
        yield put(purgeStore())
    }
}
