import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {AppToggle} from 'components/DropDown/ApplicationInteractiveDropdown'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withPanel from 'containers/withPanel'

import {encryption} from 'permissions/panel/actions'
import {setEncryptionState} from 'modules/panels/store/actions'
import {MODULE_TYPE_BBA, MODULE_TYPE_GPRS, moduleTypeTitle} from 'constants/moduleTypes'

import {withFeatureRejection} from 'containers/withFeature'
import {selectEncryption} from 'modules/features/store/selectors'
import Void from 'components/Void'

class ToggleEncryptionItem extends Component {
    static propTypes = {
        type: PropTypes.oneOf([MODULE_TYPE_BBA, MODULE_TYPE_GPRS]),

        panelId: PropTypes.number.isRequired,
        encrypted: PropTypes.bool,
        connected: PropTypes.bool,

        label: PropTypes.string,
        handle: PropTypes.func.isRequired,
    }

    handleEncryptionChange = (e) => {
        const {handle, panelId, type} = this.props

        handle(panelId, type, e.target.checked)
    }

    render() {
        const {encrypted, label, connected} = this.props
        return (
            <div className="menu-item menu-item--toggle">
                <AppToggle
                    permission={encryption}
                    label={label}
                    onChange={this.handleEncryptionChange}
                    checked={encrypted || false}
                    disabled={connected}
                />
            </div>
        )
    }
}

export default compose(
    withPermission({isVisible: encryption}),
    withVisibility(),
    withPanel(),
    connect(
        (
            state,
            {
                panel: {
                    id,
                    modules: {bba, gprs},
                },
                type,
            }
        ) => ({
            panelId: id,
            encrypted:
                type === MODULE_TYPE_BBA ? bba && bba.encrypted : gprs && gprs.encrypted,
            label: moduleTypeTitle(type),
        }),
        (dispatcher) =>
            bindActionCreators(
                {
                    handle: setEncryptionState,
                },
                dispatcher
            )
    ),
    withFeatureRejection(selectEncryption, Void)
)(ToggleEncryptionItem)
