import dataMapper from 'utils/dataMapper'
import parseDate from 'api/base/parseDate'
import {isNeoSerial} from 'utils/panelType'
import isNil from 'lodash-es/isNil'

export const mapInteractiveUser = dataMapper((user) => ({
    id: parseInt(user.pru_id),
    email: user.pru_email,
    status: user.pru_status,
    createdAt: parseDate(user.pru_created_timestamp),
    panels: parseInt(user.panels),
}))

export const keysMap = {
    id: 'id',
    email: 'pru_email',
    status: 'pru_status',
    createdAt: 'pru_created_timestamp',
}

export const mapInteractiveUserPanels = dataMapper((interactiveUserPanel) => ({
    id: Number(interactiveUserPanel.unt_id),
    groupId: Number(interactiveUserPanel.utg_id),
    serial: interactiveUserPanel.unt_serial,
    isNeo: isNeoSerial(interactiveUserPanel.unt_serial),
    account: interactiveUserPanel.unt_account,
    group: interactiveUserPanel._utg_name,
    role: interactiveUserPanel.role,
    label: interactiveUserPanel.label,
}))

export const mapPanelInteractiveUsers = dataMapper((interactiveUser) => ({
    id: Number(interactiveUser.id),
    role: interactiveUser.role,
}))

export const mapOnePanelInteractiveUsers = dataMapper((user) => ({
    id: parseInt(user.id),
    role: user.role,
    label: user.label,
    email: user.email,
    createdAt: parseDate(user.created_timestamp),
    isAssign: !isNil(user.email),
    status: user.status,
    powerUserId: user.pru_id,
}))

export const assignUserKeyMap = {
    email: 'email',
    panelId: 'unt_id',
    panelUserId: 'pxu_panel_id',
}
