import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import List from 'ipmp-react-ui/List'

import {SettingsPageBlock} from '../SettingsPageBlock'
import EditRotationButton from './Buttons/EditRotationButton'

import {__} from 'utils/i18n'

class Rotation extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            maxEventAge: PropTypes.number,
            maxProcessAge: PropTypes.number,
            maxReportsAge: PropTypes.number,
        }),
    }

    render() {
        const {data} = this.props

        return (
            <div>
                <h1 className="card-header">
                    {__('Rotation')}
                    <EditRotationButton />
                </h1>

                <List className="card-content">
                    {this.renderListItem(__('Events Age [days]'), data.maxEventAge)}
                    {this.renderListItem(__('Process Age [days]'), data.maxProcessAge)}
                    {this.renderListItem(__('Report Age [days'), data.maxReportsAge)}
                </List>
            </div>
        )
    }
}

export default compose(
    connect(({system: {settings: {rotation}}}) => ({...rotation}))
)(Rotation)
