import React, {Component} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import withLoader from 'containers/withLoader'
import {
    reset,
    fetch,
    fetchNewer,
    fetchOlder,
    fetchRecent,
    startPoll,
    stopPoll,
} from 'modules/events/bySerialList/actions'
import {fetchVideo} from 'modules/events/one/actions'

import Event from 'components/Sidebar/EventsSidebar'
import Layout, {ScrollView} from 'ipmp-react-ui/Layout'
import Spinner from 'ipmp-react-ui/Spinner'
import Button from 'ipmp-react-ui/Button'
import __ from 'utils/i18n'
import Table from './EventsBySerialTable'

class EventsBySerialContent extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        row: PropTypes.shape({
            id: PropTypes.number,
            panelId: PropTypes.number,
        }).isRequired,
        hasNewer: PropTypes.bool,
        hasOlder: PropTypes.bool,
        newerCount: PropTypes.number,
        isNewerLoading: PropTypes.bool,
        isOlderLoading: PropTypes.bool,
        fetch: PropTypes.func,
        fetchNewer: PropTypes.func,
        fetchRecent: PropTypes.func,
        fetchOlder: PropTypes.func,
    }

    state = {
        selected: this.props.id,
        row: this.props.row,
    }

    select = ({id}) => {
        this.setState({selected: id})
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.row && prevState.row && nextProps.row.id !== prevState.row.id) {
            fetch(prevState.row.id)
            return {
                selected: nextProps.row.id,
                row: nextProps.row,
            }
        }

        return {
            row: nextProps.row,
        }
    }

    renderTable() {
        const {
            hasNewer,
            hasOlder,
            newerCount,
            isNewerLoading,
            isOlderLoading,
            fetchNewer,
            fetchRecent,
            fetchOlder,
        } = this.props
        const {selected} = this.state

        return (
            <ScrollView>
                {hasNewer && (
                    <div className="btnHolder">
                        {isNewerLoading ? (
                            <Spinner />
                        ) : (
                            <Button onClick={fetchNewer}>{__('Load newer')}</Button>
                        )}
                    </div>
                )}

                {!!newerCount && (
                    <div className="btnHolder">
                        {isNewerLoading ? (
                            <Spinner />
                        ) : (
                            <Button onClick={fetchRecent}>
                                {__('Load %d new events', newerCount)}
                            </Button>
                        )}
                    </div>
                )}

                <Table selected={selected} onRowClick={this.select} />

                {hasOlder && (
                    <div className="btnHolder">
                        {isOlderLoading ? (
                            <Spinner />
                        ) : (
                            <Button onClick={fetchOlder}>{__('Load older')}</Button>
                        )}
                    </div>
                )}
            </ScrollView>
        )
    }

    render() {
        const {row} = this.props
        const {selected} = this.state

        const panelId = row && row.panelId

        return (
            <Layout className="eventsBySerial">
                {this.renderTable()}
                {selected && (
                    <Event key={selected} eventId={selected} panelId={panelId} />
                )}
            </Layout>
        )
    }
}

export default compose(
    connect(
        ({events}, {match}) => {
            const id = parseInt(match.params.id)
            const serial = match.params.serial
            const {
                isLoading,
                error,
                hasNewer,
                hasOlder,
                isNewerLoading,
                isOlderLoading,
                newerCount,
            } = events.bySerial

            return {
                key: serial,
                id,
                serial,
                hasNewer,
                hasOlder,
                isNewerLoading,
                isOlderLoading,
                isLoading,
                newerCount,
                error,
                row: events.store.byIds[id] || {},
            }
        },
        (dispatch) =>
            bindActionCreators(
                {
                    reset,
                    fetch,
                    fetchNewer,
                    fetchOlder,
                    fetchVideo,
                    fetchRecent,
                    startPoll,
                    stopPoll,
                },
                dispatch
            )
    ),
    withLoader(
        ({reset, fetch, id, serial, startPoll}) => {
            if (serial) {
                reset(serial)
                startPoll()
                id && fetch(id)
            }
        },
        ({stopPoll}) => stopPoll()
    )
)(EventsBySerialContent)
