import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'

import withProps from 'containers/withProps'
import __ from 'utils/i18n'
import withInteractiveUsers from 'containers/withInteractiveUsers'
import withConfirmation from 'containers/withConfirmation'

export default compose(
    withInteractiveUsers(),
    withProps(({enable, interactiveUser}) => ({
        label: __('Enable'),
        onClick: () => enable(interactiveUser.id),
        message: __('Do you really want to enable %s?', interactiveUser.email),
        title: __('Enable user'),
    })),
    withConfirmation()
)(Button)
