import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {hide} from 'modules/modals/actions'

import Modal from 'ipmp-react-ui/Modal'
import CardModalError from 'ipmp-react-ui/CardModalError'
import {__} from 'utils/i18n'

const MixedVendorError = ({hide}) => (
    <Modal onClose={hide}>
        <CardModalError
            onClose={hide}
            title={__('Mixed vendors')}
            message={__(
                "You can't simultaneously push basic configuration to Neo and PowerMaster panels"
            )}
        />
    </Modal>
)

MixedVendorError.propTypes = {
    hide: PropTypes.func.isRequired,
}

export default connect(null, (dispatch) => bindActionCreators({hide}, dispatch))(
    MixedVendorError
)
