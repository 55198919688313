import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const clear = createAction('REMOTE_INSPECTIONS/RESULTS/CLEAR')
export const update = createAction('REMOTE_INSPECTIONS/RESULTS/UPDATE')
export const markAsViewed = createAction(
    'REMOTE_INSPECTIONS/RESULTS/MARK_AS_VIEWED',
    toArray
)
export const sendEmail = createAction('REMOTE_INSPECTIONS/RESULTS/SEND_EMAIL', toArray)
