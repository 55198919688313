import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import get from 'lodash/get'
import reduce from 'lodash/reduce'

import Page from 'ipmp-react-ui/Page'

import {withLocationChange} from 'containers/withLocationChange'
import ActivatePanelInteractiveBar from 'pages/Panel/InteractiveBar/ActivatePanelInteractiveBar'
import ConfigurationChangesInteractiveBar from 'pages/Panel/InteractiveBar/ConfigurationChangesInteractiveBar'
import MakeBasicConfigurationInteractiveBar from 'pages/Panel/InteractiveBar/MakeBasicConfigurationInteractiveBar'
import {getItemsByKeys, getMenuItems} from 'pages/Panel/PanelPageRouting'
import {content} from 'permissions/panel/page'
import * as tabs from 'permissions/panel/navigation'

import withPanel, {withRouterPanelId} from 'containers/withPanel'
import withLoader from 'containers/withLoader'
import {withPermission, withRejection} from 'containers/withPermission'
import withLifeCycle from 'containers/withLifeCycle'

import PanelPageTop from './PanelPageTop'
import PanelPageBar from './PanelPageBar'
import PanelPageContent from './PanelPageContent'
import PanelRemarks from './PanelRemarks'

import withFeatures, {filterPanelTabsWithFeature} from 'containers/withFeature'
import {selectDiscovery} from 'modules/features/store/selectors'
import has from 'lodash-es/has'

class PanelPage extends Page {
    static propTypes = {
        isDiscoveryApplicable: PropTypes.bool,
        items: PropTypes.array,
    }

    state = {
        isOpenedRemarks: false,
    }

    openRemarks = () => this.setState({isOpenedRemarks: true})

    closeRemarks = () => this.setState({isOpenedRemarks: false})

    renderTop() {
        return <PanelPageTop openRemarks={this.openRemarks} {...this.props} />
    }

    renderBar() {
        return <PanelPageBar menu={this.props.items} />
    }

    renderContent() {
        const {panel, items} = this.props

        if (Object.keys(items).length === 0) {
            return null
        }

        return (
            <Fragment>
                <PanelPageContent routes={items} />

                <PanelRemarks
                    panelId={panel.id}
                    opened={this.state.isOpenedRemarks}
                    onClose={this.closeRemarks}
                />
            </Fragment>
        )
    }

    get hasActivation() {
        return has(this.props.panel, 'isActivated')
    }

    renderInteractiveBar() {
        const {id, isActivated} = this.props.panel

        if (this.hasActivation && !isActivated) {
            return [<ActivatePanelInteractiveBar id={id} key="activate-panel" />]
        }

        return [
            <ConfigurationChangesInteractiveBar key="configuration" />,
            <MakeBasicConfigurationInteractiveBar key="make-basic-configuration" />,
        ]
    }
}

export default compose(
    withPermission({
        isAllowed: content,
        allowedItems: {
            ...tabs,
        },
    }),
    withRejection(),
    withPanel(),
    withRouterPanelId(),
    withFeatures(),
    connect(
        (
            state,
            {allowedItems, panel, match, isLoading, isFeatureLoading, panelId, features}
        ) => {
            const isPanelFeatures = Boolean(features)
            const isLoadingBeforeFetch = !panel || !isPanelFeatures

            if (isLoadingBeforeFetch) {
                return {
                    isLoading: isLoadingBeforeFetch,
                    isPanelFeatures,
                }
            }
            const isDiscoveryApplicable = selectDiscovery(state, {
                panelId,
            }).isAvailable
            const isContentHidden = !panel.isActivated && isDiscoveryApplicable

            if (isContentHidden) {
                allowedItems = {
                    info: allowedItems.info,
                }
            }

            const vendorItems = getMenuItems(panel.vendor)
            let mergedItems = reduce(
                allowedItems,
                (res, isAllowed, item) => {
                    if (isAllowed && get(vendorItems, item, false)) {
                        res.push(item)
                    }
                    return res
                },
                []
            )

            const items = filterPanelTabsWithFeature(
                state,
                getItemsByKeys(mergedItems),
                match.params.id
            )

            return {
                items,
                key: match.params.id,
                isLoading: isLoading || isFeatureLoading,
                isDiscoveryApplicable,
                isPanelFeatures,
            }
        }
    ),
    withLoader(({fetch, fetchFeatures, fetchFirmware, panelId, isPanelFeatures}) => {
        !isPanelFeatures && fetchFeatures(panelId)
        fetchFirmware()
        fetch()
    }),
    withLocationChange(({location, match}, {remember}) => {
        remember(location.pathname)
    }),
    withLifeCycle({
        onMount({startPollPanelData}) {
            startPollPanelData()
        },

        onUnmount({stopPollPanelData}) {
            stopPollPanelData()
        },
    })
)(PanelPage)
