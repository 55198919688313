import React, {Component} from 'react'
import {compose} from 'redux'
import get from 'lodash-es/get'

import withPanelInfo from 'containers/withPanelInfo'

import List, {ListHintItem} from 'ipmp-react-ui/List'
import {__} from 'utils/i18n'
import panelInfo from 'constants/panelInfo'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {info} from 'permissions/panel/info/page'

import EditPanelInfoButton from './Buttons/EditPanelInfoButton'
import Empty from 'ui/Empty'

class PanelInfo extends Component {
    getFirmavareValue(key) {
        const {panel} = this.props

        return get(panel, ['firmware', key])
    }

    render() {
        const {panel, fotaAotaVersions} = this.props
        const firmware = panel.firmware || {}

        const empty = <Empty>{__('not set')}</Empty>

        return (
            <div>
                <div className="card-header">
                    {__('Panel Info')}
                    <EditPanelInfoButton />
                </div>

                <List className="card-content" twoCols>
                    {panel.simNumber && (
                        <ListHintItem name={__('Sim number')} text={panel.simNumber} />
                    )}

                    {Object.keys(firmware).map((key) => (
                        <ListHintItem
                            key={key}
                            name={panelInfo(key)}
                            text={this.getFirmavareValue(key) || empty}
                        />
                    ))}
                    {Object.keys(fotaAotaVersions).map((key) => (
                        <ListHintItem
                            key={key}
                            name={panelInfo(key)}
                            text={fotaAotaVersions[key] || empty}
                        />
                    ))}
                </List>
            </div>
        )
    }
}

export default compose(
    withPermission({isVisible: info}),
    withVisibility(),
    withPanelInfo()
)(PanelInfo)
