import has from 'lodash-es/has'

import {__} from 'utils/i18n'
import {warn} from 'utils/log'

import {ReactComponent as unknown} from 'ipmp-react-ui/icons/signal-unknown.svg'
import {ReactComponent as level0} from 'ipmp-react-ui/icons/signal-level-0.svg'
import {ReactComponent as level1} from 'ipmp-react-ui/icons/signal-level-1.svg'
import {ReactComponent as level2} from 'ipmp-react-ui/icons/signal-level-2.svg'
import {ReactComponent as level3} from 'ipmp-react-ui/icons/signal-level-3.svg'
import {ReactComponent as level4} from 'ipmp-react-ui/icons/signal-level-4.svg'

import {ReactComponent as oneWay} from 'ipmp-react-ui/icons/signal-1way.svg'
import {ReactComponent as noConnection} from 'ipmp-react-ui/icons/signal-no-connection.svg'

export const SIGNAL_LEVEL_BAD = 'bad'
export const SIGNAL_LEVEL_POOR = 'poor'
export const SIGNAL_LEVEL_OK = 'ok'
export const SIGNAL_LEVEL_GOOD = 'good'
export const SIGNAL_LEVEL_STRONG = 'strong'
export const SIGNAL_LEVEL_1_WAY = '1 way'
export const SIGNAL_LEVEL_NO_CONNECTION = 'no connection'
export const SIGNAL_LEVEL_NO_RESULT = 'no result'
export const SIGNAL_LEVEL_NO_TEST = 'no test'
export const SIGNAL_LEVEL_PREENROLL = 'preenroll'
export const SIGNAL_LEVEL_TOO_EARLY = 'too early'
export const SIGNAL_LEVEL_UNDEFINED = 'undefined'

export const NETWORK_UNKNOWN = 'Unknown'
export const NETWORK_2G = '2G'
export const NETWORK_3G = '3G'
export const NETWORK_CDMA = 'CDMA'
export const NETWORK_WCDMA = 'WCDMA'
export const NETWORK_LTE = 'LTE'
export const NETWORK_LTE_ADVANCED = 'LTE ADVANCED'

export const deviceSignals = [
    SIGNAL_LEVEL_BAD,
    SIGNAL_LEVEL_POOR,
    SIGNAL_LEVEL_OK,
    SIGNAL_LEVEL_GOOD,
    SIGNAL_LEVEL_STRONG,
    SIGNAL_LEVEL_1_WAY,
    SIGNAL_LEVEL_NO_CONNECTION,
    SIGNAL_LEVEL_NO_RESULT,
    SIGNAL_LEVEL_NO_TEST,
    SIGNAL_LEVEL_PREENROLL,
    SIGNAL_LEVEL_TOO_EARLY,
    SIGNAL_LEVEL_UNDEFINED,
]

const deviceSignalIcons = {
    [SIGNAL_LEVEL_BAD]: level1,
    [SIGNAL_LEVEL_POOR]: level2,
    [SIGNAL_LEVEL_GOOD]: level3,
    [SIGNAL_LEVEL_STRONG]: level4,
    [SIGNAL_LEVEL_1_WAY]: oneWay,
    [SIGNAL_LEVEL_NO_CONNECTION]: noConnection,
    [SIGNAL_LEVEL_PREENROLL]: noConnection,
    [SIGNAL_LEVEL_NO_RESULT]: unknown,
    [SIGNAL_LEVEL_NO_TEST]: unknown,
    [SIGNAL_LEVEL_TOO_EARLY]: unknown,
    [SIGNAL_LEVEL_UNDEFINED]: unknown,
}

const gsmModuleSignal = {
    [SIGNAL_LEVEL_BAD]: level0,
    [SIGNAL_LEVEL_POOR]: level1,
    [SIGNAL_LEVEL_OK]: level2,
    [SIGNAL_LEVEL_GOOD]: level3,
    [SIGNAL_LEVEL_STRONG]: level4,
}

export function deviceSignalLevelIcon(signalLevel) {
    if (has(deviceSignalIcons, signalLevel)) {
        return deviceSignalIcons[signalLevel]
    }

    warn(`Unknown device signal level ${signalLevel}`)

    return unknown
}

export function gsmSignalLevelIcon(signalLevel) {
    if (has(gsmModuleSignal, signalLevel)) {
        return gsmModuleSignal[signalLevel]
    }

    warn(`Unknown gsm signal level ${signalLevel}`)

    return unknown
}

export function signalLevelTitle(signalLevel) {
    if (!signalLevel) {
        return __('No measurements')
    }

    switch (signalLevel) {
        case SIGNAL_LEVEL_BAD:
            return __('Bad')
        case SIGNAL_LEVEL_POOR:
            return __('Poor')
        case SIGNAL_LEVEL_OK:
            return __('Ok')
        case SIGNAL_LEVEL_GOOD:
            return __('Good')
        case SIGNAL_LEVEL_STRONG:
            return __('Strong')
        case SIGNAL_LEVEL_1_WAY:
            return __('One way')
        case SIGNAL_LEVEL_NO_CONNECTION:
            return __('No connection')
        case SIGNAL_LEVEL_NO_RESULT:
            return __('No result')
        case SIGNAL_LEVEL_NO_TEST:
            return __('No test')
        case SIGNAL_LEVEL_PREENROLL:
            return __('Preenroll')
        case SIGNAL_LEVEL_TOO_EARLY:
            return __('Too early')
        case SIGNAL_LEVEL_UNDEFINED:
            return __('Undefined')
    }

    warn(`Unknown signal level ${signalLevel}`)

    return signalLevel
}

export function networkTitle(network) {
    switch (network) {
        case NETWORK_UNKNOWN:
            return __('Unknown')
    }
    return network
}

export function networkShortTitle(network) {
    switch (network) {
        case NETWORK_UNKNOWN:
            return '?'
        case NETWORK_LTE_ADVANCED:
            return 'LTE+'
    }

    return network
}
