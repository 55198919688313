import {createMatchSelector} from 'react-router-redux'
import {all} from 'redux-saga/effects'
import get from 'lodash-es/get'

import createListSaga from 'modules/higherOrder/createListSaga'
import {selectPerPage} from 'modules/settings/selectors'

import {
    fetchConfigCompatible as fetch,
    suggestConfigCompatible as suggest,
} from 'api/panels'
import * as actions from './actions'

import {update} from 'modules/panels/store/actions'

const pushBasicConfigurationIdMatchSelector = createMatchSelector(
    '/system/basicConfiguration/:basicConfigId/push'
)

const selector = (state) => {
    const match = pushBasicConfigurationIdMatchSelector(state)
    const basicConfigId = get(match, 'params.basicConfigId', null)

    return {
        ...state.basicConfiguration.push,
        perPage: selectPerPage(state, 'pushBasicConfigurations'),
        listRoute: 'basicConfigurations',
        basicConfigId,
    }
}

export default function* () {
    yield all([createListSaga({fetch, suggest}, actions, update, selector)])
}
