import validate from 'validate.js'
import isFunction from 'lodash-es/isFunction'
import moment, {isMoment} from 'moment'

import {__} from 'utils/i18n'
import toArray from 'utils/toArray'

const defaultFormat = 'L'
const ipv4RegExp = /(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/

function extendValidators() {
    validate.extend(validate.validators.datetime, {
        parse(value) {
            if (isMoment(value)) {
                return value
            }

            const parsed = moment.utc(value, defaultFormat)
            return +parsed
        },

        format(value) {
            return moment(value).format(defaultFormat)
        },
    })

    validate.validators.deviceId = (code, {invalid, notExists, existence}) => {
        if (!code) {
            return
        }

        const digits = code.replace(/\D/g, '')

        if (digits.length !== 7) {
            return invalid
        }

        if (parseInt(digits.substr(3)) > 8191) {
            return invalid
        }

        if (isFunction(existence) && !existence(code)) {
            return notExists
        }
    }

    validate.validators.noEmptyEquality = (value, options, name, values) => {
        const {attribute} = options
        const presence = values[attribute] !== null
        if (presence) {
            const message = validate.single(value, {presence})
            if (message) {
                return message
            }
        }

        return validate.validators.equality(value, options, attribute, values)
    }

    validate.validators.phone = (value) =>
        validate.single(value, {
            format: {
                pattern: /^\+?[0-9]*$/,
                message: () =>
                    __('Invalid characters used. Should contain only digits and +'),
            },
            length: {
                minimum: 3,
                maximum: 16,
            },
        })

    validate.validators.each = (values, options) => {
        values = toArray(values)
        return values
            .map((value) => {
                return validate.single(value, options)
            })
            .find((result) => result)
    }

    validate.validators.parsed = (value, {parse, validators}) => {
        const parsed = validate.isFunction(parse) ? parse(value) : value
        return validate.single(parsed, validators)
    }

    validate.validators.password = (value) =>
        validate.single(value, {
            length: {
                minimum: 6,
            },
            format: {
                pattern: /^\S+.*\S+$/,
                message: () => __("Password shouldn't starts or ends with spaces"),
            },
        })

    validate.validators.ipv4 = function validateIpv4(value, options) {
        options = validate.extend({}, this.options, options)

        return validate.single(value, {
            format: {
                pattern: ipv4RegExp,
                message: options.message,
            },
        })
    }

    validate.validators.custom = function validateCustom(value, options) {
        if (!options) {
            return
        }

        if (typeof options === 'string') {
            return options
        }

        options = validate.extend({}, this.options, options)
        return value.single(value, options)
    }
}

function setErrorMessages(messages) {
    Object.keys(messages).map((key) => {
        const data =
            typeof messages[key] === 'object' ? messages[key] : {message: messages[key]}

        validate.validators[key].options = {
            ...validate.validators[key].options,
            ...data,
        }
    })
}

export default function initValidate() {
    extendValidators()

    setErrorMessages({
        presence: () => __('This field is required'),
        email: () => __('Invalid email address'),
        format: () => __('Value is not acceptable'),
        length: {
            tooShort: (value, name, args) =>
                __('Needs to have %(minimum)d characters or more', args),
            tooLong: (value, name, args) =>
                __('Needs to have %(maximum)d characters or less', args),
        },
        numericality: {
            notInteger: () => __('Is not a number'),
            notGreaterThanOrEqualTo: (value, name, args) =>
                __('Must be greater than or equal to %(greaterThanOrEqualTo)d', args),
            notLessThanOrEqualTo: (value, name, args) =>
                __('Must be less than or equal to %(lessThanOrEqualTo)d', args),
        },
        ipv4: () => __('Must be valid ipv4 address, like 192.168.168.121'),
    })
}
