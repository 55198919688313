import withPerPage from 'containers/withPerPage'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {
    init,
    fetch,
    reset,
    setStart,
    startPoll,
    stopPoll,
} from 'modules/interactiveUsers/list/actions'
import {enable, suspend, resetPassword} from 'modules/interactiveUsers/store/actions'

import * as panelInteractiveUsersActions from 'modules/panelInteractiveUsers/list/actions'
import {selectPanelInteractiveUsersList} from 'modules/panelInteractiveUsers/list/selector'
import {withRouterPanelId} from './withPanel'

export default function withInteractiveUsers() {
    return compose(
        withPerPage('interactiveUsers'),
        connect(
            (state) => {
                const {isLoading, page, error, total, start} = state.interactiveUsers.list

                return {
                    rows: page.map((id) => state.interactiveUsers.store.byIds[id]),
                    isLoading,
                    error,
                    total,
                    start,
                }
            },
            (dispatch) =>
                bindActionCreators(
                    {
                        init,
                        fetch,
                        reset,
                        enable,
                        suspend,
                        resetPassword,
                        onPageChange: setStart,
                        startPoll,
                        stopPoll,
                    },
                    dispatch
                )
        )
    )
}

export function withPanelInteractiveUsers(params) {
    return compose(
        withRouterPanelId(),
        connect(selectPanelInteractiveUsersList, (dispatch) =>
            bindActionCreators(
                {
                    init: panelInteractiveUsersActions.init,
                    fetch: panelInteractiveUsersActions.fetch,
                    reset: panelInteractiveUsersActions.reset,
                    startPoll: panelInteractiveUsersActions.startPoll,
                    stopPoll: panelInteractiveUsersActions.stopPoll,
                    suspend,
                    enable,
                },
                dispatch
            )
        )
    )
}
