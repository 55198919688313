import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {select, selectNext, selectPrevious} from 'modules/remoteInspections/list/actions'

import {markAsViewed, sendEmail} from 'modules/remoteInspections/results/actions'
import {initiateNow} from 'modules/remoteInspections/store/actions'
import {withRouterPanelId} from './withPanel'

export default function withRemoteInspectionsResult() {
    return connect(
        ({remoteInspections: {store, results, list}}) => {
            const {selectedId, hasNext, hasPrev} = list
            const inspection = selectedId ? store[selectedId] : null
            const result =
                inspection && inspection.latestId ? results[inspection.latestId] : null

            return {
                inspection,
                result,
                hasNext,
                hasPrev,
                selectedId,
            }
        },

        (dispatch) =>
            bindActionCreators(
                {
                    select,
                    selectNext,
                    selectPrevious,
                    markAsViewed,
                    sendEmail,
                    initiateNow,
                },
                dispatch
            ),

        (
            {inspection, result, ...state},
            {markAsViewed, sendEmail, initiateNow, ...dispatch},
            own
        ) => ({
            inspection,
            result,
            ...state,
            ...dispatch,

            review: () => result && markAsViewed([result.id]),
            sendEmail: () => result && sendEmail([result.id]),
            initiate: () => inspection && initiateNow([inspection.id]),

            ...own,
        })
    )
}

export function withPanelRemoteInspectionsResult() {
    return compose(
        withRouterPanelId(),
        connect(
            ({remoteInspections: {store, results}}, {panelId}) => {
                const inspection = panelId ? store[panelId] : null

                return {
                    inspection,
                }
            },

            (dispatch) =>
                bindActionCreators(
                    {
                        markAsViewed,
                        sendEmail,
                        initiateNow,
                    },
                    dispatch
                ),

            (
                {inspection, ...state},
                {markAsViewed, sendEmail, initiateNow, ...dispatch},
                {remoteInspectionResult, ...own}
            ) => ({
                inspection,
                remoteInspectionResult,
                ...state,
                ...dispatch,

                review: () =>
                    remoteInspectionResult && markAsViewed([remoteInspectionResult.id]),
                sendEmail: () =>
                    remoteInspectionResult && sendEmail([remoteInspectionResult.id]),
                initiate: () => inspection && initiateNow([inspection.id]),

                ...own,
            })
        )
    )
}
