import React from 'react'
import {Link} from 'react-router-dom'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import classes from 'classnames'
import get from 'lodash-es/get'

import {panel} from 'permissions/navigation'
import path from 'utils/path'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import withRoute from 'containers/withRoute'

import {ReactComponent as IconHistory} from 'ipmp-react-ui/icons/history.svg'

const RecentPanelLink = ({match, id, serial, to}) => {
    if (!id) {
        return null
    }

    return (
        <div className="nav-link-wrapper">
            <Link
                id={id}
                className={classes('nav-link', {'nav-link--active': match})}
                to={to}
            >
                <div className="nav-link-content">
                    <IconHistory className="nav-link-icon" />
                    {serial}
                </div>
            </Link>
        </div>
    )
}

export default compose(
    withRouter,
    withPermission({isVisible: panel}),
    withVisibility(),
    connect(({panels: {recent, store}}) => {
        if (!recent || !recent.id) {
            return {}
        }

        const {id, to, serial} = recent

        return {
            id,
            serial: get(store.byIds, id + '.serial', serial),
            path: path('panel', {id}),
            to,
        }
    }),
    withRoute()
)(RecentPanelLink)
