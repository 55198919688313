import {withVisibility} from 'containers/withVisibility'
import {selectDeviceByZoneAndType} from 'modules/devices/list/selectors'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import get from 'lodash-es/get'

import {videoOnDemand, videoAttached} from 'permissions/events/actions'
import devicesList from 'permissions/panel/devices/list'

import {fetchVideo, recordVideo} from 'modules/events/one/actions'
import {fetch as fetchDevices} from 'modules/devices/list/actions'
import {setBypass} from 'modules/devices/list/actions'
import withPoller from 'containers/withPoller'
import withLoader from 'containers/withLoader'
import {withPermission} from 'containers/withPermission'
import {exports, request} from 'permissions/panel/devices/video/actions'

import VideoTabsComponent from './VideoTabs'
import EventDevice from 'components/Devices/EventDevice'
import Panel from 'components/Devices/Panel'
import {SideBar} from 'ipmp-react-ui/Layout'
import withFeature from 'containers/withFeature'

const VideoTabs = compose(
    withPermission({
        hasLive: videoOnDemand,
        isRequestAllowed: request,
        isExportAllowed: exports,
    }),
    connect((state, {event: {zone, deviceType, panelId}, hasLive}) => {
        const device = selectDeviceByZoneAndType(state, {panelId, deviceType, zone})
        return {
            device: device,
            hasLive: hasLive,
        }
    }),
    withFeature(),
    withLoader(({fetch, fetchFeaturesWithoutLoader, panelId}) => {
        fetch()
        fetchFeaturesWithoutLoader(panelId)
    }),
    withPoller(1000, ({fetch}) => fetch())
)(VideoTabsComponent)

const DeviceCard = compose(
    withPermission({isVisible: devicesList}),
    withVisibility(),
    connect(
        (state, {panelId, event: {zone, deviceType}}) => {
            const device = selectDeviceByZoneAndType(state, {panelId, deviceType, zone})

            return {
                isLoading: !device,
                device,
            }
        },
        (dispatch, {panelId}) =>
            bindActionCreators(
                {
                    fetchDevices: () => fetchDevices(panelId),
                    setBypass: (deviceId, state) =>
                        setBypass(panelId, {[deviceId]: state}),
                },
                dispatch
            )
    ),
    withLoader(({fetchDevices}) => fetchDevices()),
    withPoller(5000, ({fetchDevices}) => fetchDevices())
)(EventDevice)

const PanelCard = compose(
    withPermission({isVisible: devicesList}),
    withVisibility(),
    connect(
        ({panels}, {panelId}) => ({
            panel: panels.store.byIds[panelId],
            isLoading: !panels.store.byIds[panelId],
        }),
        (dispatch, {panelId}) =>
            bindActionCreators(
                {
                    fetchDevices: () => fetchDevices(panelId),
                },
                dispatch
            )
    ),
    withLoader(({fetchDevices}) => fetchDevices())
)(Panel)

class EventsBySerialSidebar extends Component {
    static propTypes = {
        event: PropTypes.object,
        device: PropTypes.object,
        panel: PropTypes.object,
        hasDevice: PropTypes.bool,
        hasPanel: PropTypes.bool,
        fetch: PropTypes.func,
        startRecordVideo: PropTypes.func,
        fetchDevices: PropTypes.func,
        fetchPanel: PropTypes.func,
    }

    renderDeviceCard() {
        const {event, panelId} = this.props
        const {hasDevice, hasPanel} = event

        if (hasDevice) {
            return <DeviceCard {...{event, panelId}} />
        }

        if (hasPanel) {
            return <PanelCard panelId={panelId} />
        }
    }

    render() {
        const {event, videoAttached, ...props} = this.props

        if (!event.hasVideo || !videoAttached) {
            return (
                <SideBar>
                    <div className="sidebar-content">{this.renderDeviceCard()}</div>
                </SideBar>
            )
        }

        return (
            <SideBar>
                <VideoTabs
                    video={event.video}
                    isLoading={!event.video}
                    event={event}
                    {...props}
                >
                    {this.renderDeviceCard()}
                </VideoTabs>
            </SideBar>
        )
    }
}

export default compose(
    connect(
        ({events}, {eventId}) => ({
            event: events.store.byIds[eventId] || {},
        }),
        (dispatch, {eventId, panelId}) =>
            bindActionCreators(
                {
                    fetch: () => fetchVideo(eventId),
                    startRecordVideo: (zoneId) => recordVideo(eventId, panelId, zoneId),
                },
                dispatch
            )
    ),
    withPermission({
        videoAttached: videoAttached,
    })
)(EventsBySerialSidebar)
