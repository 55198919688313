import {all, select, put, takeEvery} from 'redux-saga/effects'

import * as api from 'api/panels'
import * as actions from './actions'

import {update, purge} from 'modules/panels/store/actions'
import {selectPerPage} from 'modules/settings/selectors'
import {fetch} from 'modules/interactiveUserPanels/list/actions'

import listSaga from 'modules/higherOrder/createListSaga'
import {assignUserToPanel} from 'modules/forms/handlers'
import {isNil} from 'lodash-es'

const selector = (state) => ({
    ...state.interactiveUserPanels.addPanelList,
    perPage: selectPerPage(state, 'interactiveUserAddPanel'),
    listRoute: 'interactiveUsers',
})

export default function* () {
    yield all([
        listSaga(api, actions, update, selector, purge),
        takeEvery(assignUserToPanel.SUCCESS, watchAssignUserToPanel),
    ])
}

function* watchAssignUserToPanel() {
    const interactiveUserId = yield select(
        (state) => state.interactiveUserPanels.list.interactiveUserId
    )

    // On interactive users page
    if (isNil(interactiveUserId)) {
        return
    }

    yield put(fetch(interactiveUserId))
}
