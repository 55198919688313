import has from 'lodash-es/has'

import * as t from 'constants/processTypes'
import {__} from 'utils/i18n'
import {err} from 'utils/log'

export default function processType(type) {
    switch (type) {
        case t.PROCESS_TYPE_FEATURES:
            return __('Features')
        case t.PROCESS_TYPE_PMAXSOFTWAREUPGRADE:
            return __('Upgrade Software')
        case t.PROCESS_TYPE_PLINKSOFTWAREUPGRADE:
            return __('Upgrade Plink')
        case t.PROCESS_TYPE_PMAXANONYMOUS:
            return __('Anonymous')
        case t.PROCESS_TYPE_PMAXSETSOAKZONE:
            return __('Set soak')
        case t.PROCESS_TYPE_PMAXCANCELSOAKZONE:
            return __('Cancel soak')
        case t.PROCESS_TYPE_PMAXSETBYPASSZONE:
            return __('Enable bypass')
        case t.PROCESS_TYPE_PMAXCLEARBYPASSZONE:
            return __('Disable bypass')
        case t.PROCESS_TYPE_PMAXCONFIGBACKUP:
            return __('Backup configuration')
        case t.PROCESS_TYPE_PMAXCONFIGBASICUPLOAD:
            return __('Push basic configuration')
        case t.PROCESS_TYPE_PMAXCONFIGDOWNLOAD:
            return __('Download configuration')
        case t.PROCESS_TYPE_PMAXCONFIGUPLOAD:
            return __('Upload configuration')
        case t.PROCESS_TYPE_PMAXFILMONDEMAND:
            return __('Record video')
        case t.PROCESS_TYPE_PMAXLOGLEGACY:
            return __('Download legacy log')
        case t.PROCESS_TYPE_PMAXLOGSTANDARD:
            return __('Download standard log')
        case t.PROCESS_TYPE_NEOLIVELOG:
            return __('Download neo log')
        case t.PROCESS_TYPE_PMAXPYTHONUPGRADE:
            return __('Python Upgrade')
        case t.PROCESS_TYPE_PMAXSETCUSTOMZONES:
            return __('Set custom zones')
        case t.PROCESS_TYPE_PMAXSTATEGET:
            return __('Refresh state')
        case t.PROCESS_TYPE_PMAXSTATESET:
            return __('Set state')
        case t.PROCESS_TYPE_PMAXZONEADD:
            return __('Add device')
        case t.PROCESS_TYPE_PMAXZONEREMOVE:
            return __('Remove device')
        case t.PROCESS_TYPE_PMAXACTIVATESIREN:
            return __('Activate siren')
        case t.PROCESS_TYPE_PMAXDISABLESIREN:
            return __('Disable Siren')
        case t.PROCESS_TYPE_PMAXZONERSSI:
            return __('Refresh RSSI')
        case t.PROCESS_TYPE_PMAXZONEWALKTEST:
            return __('Walktest')
        case t.PROCESS_TYPE_RESETPASSWORD:
            return __('Reset Password')
        case t.PROCESS_TYPE_REMOTEINSPECTION:
            return __('Remote Inspection')
        case t.PROCESS_TYPE_SOFTWAREREPOSYNC:
            return __('Sync software repository')
        case t.PROCESS_TYPE_DISABLEX10:
            return __('Disable X10')
        case t.PROCESS_TYPE_ENABLEX10:
            return __('Enable X10')
        case t.PROCESS_TYPE_DIMMERX10:
            return __('Dimmer X10')
        case t.PROCESS_TYPE_BRIGHTERX10:
            return __('Brighter X10')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL0: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL1: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL2: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL3: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE0: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE1: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE2: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE3: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER0: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER1: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER2: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER3: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER0: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER1: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER2: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER3: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER0: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER1: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER2: return __('')
        //case t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER3: return __('')
        case t.PROCESS_TYPE_REPORT:
            return __('Report')
        case t.PROCESS_TYPE_PMAXSETVODMODE:
            return __('Set VOD mode')
        case t.PROCESS_TYPE_PMAXRESETRECEIVERIP:
            return __('Remove panel')
        case t.PROCESS_TYPE_PMAXASSIGNPARTITIONS:
            return __('Assign Partitions')
        case t.PROCESS_TYPE_NEOACTIVATION:
            return __('Activation')
        case t.PROCESS_TYPE_FIRMWARE_UPGRADE:
            return __('Firmware upgrade')
        case t.PROCESS_TYPE_NEO_UPGRADE:
            return __('Upgrade Software')
        case t.PROCESS_TYPE_SET_LABEL:
            return __('Set label')
        case t.PROCESS_TYPE_EDITPGM:
            return __('Set PGM')
        case t.PROCESS_TYPE_PANEL_DISCOVERY:
            return __('Panel Discovery')
        case t.PROCESS_TYPE_NEO_DIAGNOSTIC:
            return __('Read diagnostic info')
        case t.PROCESS_TYPE_REFRESH_GSM:
            return __('Refresh GSM')
    }

    err(`Unknown process type ${type}`)

    return type || __('Unknown')
}

const links = {
    [t.PROCESS_TYPE_FEATURES]: '',
    [t.PROCESS_TYPE_PMAXSOFTWAREUPGRADE]: 'panel.firmware',
    [t.PROCESS_TYPE_PLINKSOFTWAREUPGRADE]: 'panel.firmware',
    [t.PROCESS_TYPE_FIRMWARE_UPGRADE]: 'panel.firmware',
    [t.PROCESS_TYPE_NEO_UPGRADE]: 'panel.firmware',
    [t.PROCESS_TYPE_PMAXANONYMOUS]: '',
    [t.PROCESS_TYPE_PMAXSETSOAKZONE]: 'panel.devices',
    [t.PROCESS_TYPE_PMAXCANCELSOAKZONE]: 'panel.devices',
    [t.PROCESS_TYPE_PMAXSETBYPASSZONE]: 'panel.devices',
    [t.PROCESS_TYPE_PMAXCLEARBYPASSZONE]: 'panel.devices',
    [t.PROCESS_TYPE_PMAXCONFIGBACKUP]: 'panel.configuration',
    [t.PROCESS_TYPE_PMAXCONFIGBASICUPLOAD]: 'panel.configuration',
    [t.PROCESS_TYPE_PMAXCONFIGDOWNLOAD]: 'panel.configuration',
    [t.PROCESS_TYPE_PMAXCONFIGUPLOAD]: 'panel.configuration',
    [t.PROCESS_TYPE_PMAXFILMONDEMAND]: 'panel.devices',
    [t.PROCESS_TYPE_PMAXLOGLEGACY]: 'panel.log',
    [t.PROCESS_TYPE_PMAXLOGSTANDARD]: 'panel.log',
    [t.PROCESS_TYPE_NEOLIVELOG]: 'panel.log',
    [t.PROCESS_TYPE_PMAXPYTHONUPGRADE]: '',
    [t.PROCESS_TYPE_PMAXSETCUSTOMZONES]: 'panel.locations',
    [t.PROCESS_TYPE_PMAXSTATEGET]: 'panel.state',
    [t.PROCESS_TYPE_PMAXSTATESET]: 'panel.state',
    [t.PROCESS_TYPE_PMAXZONEADD]: 'panel.devices',
    [t.PROCESS_TYPE_PMAXZONEREMOVE]: 'panel.devices',
    [t.PROCESS_TYPE_PMAXACTIVATESIREN]: '',
    [t.PROCESS_TYPE_PMAXDISABLESIREN]: '',
    [t.PROCESS_TYPE_PMAXZONERSSI]: 'panel.devices',
    [t.PROCESS_TYPE_REFRESH_GSM]: 'panel.devices',
    [t.PROCESS_TYPE_PMAXZONEWALKTEST]: 'panel.devices',
    [t.PROCESS_TYPE_RESETPASSWORD]: '',
    [t.PROCESS_TYPE_REMOTEINSPECTION]: '',
    [t.PROCESS_TYPE_SOFTWAREREPOSYNC]: '',
    [t.PROCESS_TYPE_DISABLEX10]: '',
    [t.PROCESS_TYPE_ENABLEX10]: '',
    [t.PROCESS_TYPE_DIMMERX10]: '',
    [t.PROCESS_TYPE_BRIGHTERX10]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL0]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL1]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL2]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL3]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE0]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE1]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE2]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE3]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER0]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER1]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER2]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER3]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER0]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER1]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER2]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER3]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER0]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER1]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER2]: '',
    [t.PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER3]: '',
    [t.PROCESS_TYPE_REPORT]: 'panel.reports',
    [t.PROCESS_TYPE_PMAXSETVODMODE]: 'panel.devices',
    [t.PROCESS_TYPE_PMAXRESETRECEIVERIP]: '',
    [t.PROCESS_TYPE_PMAXASSIGNPARTITIONS]: '',
    [t.PROCESS_TYPE_NEOACTIVATION]: 'panel',
    [t.PROCESS_TYPE_SET_LABEL]: 'panel.devices',
    [t.PROCESS_TYPE_PANEL_DISCOVERY]: 'panel.devices',
    [t.PROCESS_TYPE_NEO_DIAGNOSTIC]: 'panel.devices',
}

export function getProcessLinkByType(type) {
    if (has(links, type)) {
        return links[type]
    }

    err(`Undeclared link for process type ${type}`)

    return null
}
