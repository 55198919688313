import createRowsResponseParser from 'api/base/createRowsResponseParser'
import {get, post} from 'api/http'
//import _get from 'lodash/get'
import generateSuggestString from 'api/base/generateSuggestString'
import generateFilterString from 'api/base/generateFilterString'
import {mapProcess} from 'api/processes'
import mapSuggests from 'api/base/mapSuggests'

import mapper from 'utils/dataMapper'
import * as DeviceTypes from 'constants/deviceType'

export const mapEvent = mapper((data) => {
    const zone =
        data.evt_zone_id && parseInt(data.evt_zone_id) !== 0
            ? parseInt(data.evt_zone_id)
            : null
    const deviceType = data.utd_name
    const hasPanel = deviceType === DeviceTypes.DEVICE_TYPE_CONTROL_PANEL
    const hasDevice =
        zone &&
        deviceType !== DeviceTypes.DEVICE_TYPE_CONTROL_PANEL &&
        Object.values(DeviceTypes).includes(deviceType)

    return {
        id: parseInt(data.evt_id),
        panelId: parseInt(data.unt_id),
        severity: data._etp_name,
        serial: data.unt_serial,
        account: data.unt_account,
        description: data.evt_description,
        datetime: data.evt_created_timestamp,
        changed: data.evt_changed_timestamp,
        binMessage: data.evt_bin_message,
        appointment: data.evt_appointment,
        path: data.evt_path,
        hasVideo: data.evt_camera_count && parseInt(data.evt_camera_count) !== 0,
        isViewed:
            data.evt_reconsideration_condition &&
            data.evt_reconsideration_condition === 'yes',
        deviceTypeId: parseInt(data.utd_id),
        deviceType,
        zone,
        hasPanel,
        hasDevice,
        partitions: data.evt_partition_bitmask
            ? data.evt_partition_bitmask.split(',')
            : [],
    }
})

const keysMap = {
    id: 'evt_id',
    panelId: 'unt_id',
    serial: 'unt_serial',
    account: 'unt_account',
    severity: '_etp_name',
    description: 'evt_description',
    isViewed: 'evt_reconsideration_condition',
    zone: 'evt_zone_id',
    binMessage: 'evt_bin_message',
    datetime: 'evt_created_timestamp',
    changed: 'evt_changed_timestamp',
    appointment: 'evt_appointment',
    path: 'evt_path',
    bitmask: 'evt_partition_bitmask',
    camera: 'evt_camera_count',
}

const mapEventVideo = ({process, ...data}) => ({
    process: process && mapProcess(process),
    ...data,
})

/**
 * @deprecated
 */
export function retrieve(start = 0, count = 10, filter = {}, sort = 'id', desc = true) {
    filter = generateFilterString(filter, keysMap)
    sort = (desc ? '-' : '') + (keysMap[sort] || sort)

    return get('/event/getallevents', {start, count, filter, sort}).then(
        createRowsResponseParser(mapEvent)
    )
}

export function fetch({start = 0, perPage: count = 10, filters, sort, query}) {
    const filter = generateFilterString(filters, keysMap)

    if (sort) {
        sort = Array.isArray(sort) ? sort : [sort]

        sort = sort
            .map((key) => {
                const desc = key[0] === '-' ? '-' : '+'

                key = key.replace(/^[-+]/, '')
                key = keysMap[key] || key

                return desc + key
            })
            .join(',')
    }

    return get('/event/getallevents', {start, count, filter, sort, query}).then(
        createRowsResponseParser(mapEvent)
    )
}

export function getVideo(id) {
    return get('/event/video', {evt_id: id}).then(({live, preloaded}) => ({
        live: live && live.map(mapEventVideo),
        preloaded: preloaded && preloaded.map(mapEventVideo),
    }))
}

export function processor(ids) {
    if (!Array.isArray(ids)) {
        ids = [ids]
    }

    return post('/event/eventprocessor', {evt_id: ids})
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('event/suggestevents', {suggest, start, count}).then(
        mapSuggests(fields, keysMap)
    )
}
