import React, {Component} from 'react'
import PropTypes from 'prop-types'

import DoubleBlindModal, {DoubleBlindModalEntry} from 'ipmp-react-ui/DoubleBlindModal'

import InteractiveUserInfo from './BlindContent/InteractiveUserInfo'
import InteractiveUserAddPanel from './BlindContent/InteractiveUserAddPanel'

export default class InteractiveUserBlind extends Component {
    static propTypes = {
        interactiveUser: PropTypes.object,
        onClose: PropTypes.func.isRequired,
    }

    state = {
        isAddPanelOpened: false,
    }

    get isInteractiveUserPanelsOpened() {
        return Boolean(this.props.interactiveUser)
    }

    onClose = () => this.props.onClose()

    onAddPanel = () => this.setState({isAddPanelOpened: true})

    onCloseAddPanel = () => this.setState({isAddPanelOpened: false})

    render() {
        const {interactiveUser} = this.props
        const {isAddPanelOpened} = this.state

        return (
            <DoubleBlindModal>
                <DoubleBlindModalEntry
                    opened={this.isInteractiveUserPanelsOpened}
                    onClose={this.onClose}
                >
                    <InteractiveUserInfo
                        interactiveUser={interactiveUser}
                        onAddPanel={this.onAddPanel}
                    />
                </DoubleBlindModalEntry>
                <DoubleBlindModalEntry
                    opened={isAddPanelOpened}
                    onClose={this.onCloseAddPanel}
                >
                    <InteractiveUserAddPanel
                        interactiveUser={interactiveUser}
                        onClose={this.onCloseAddPanel}
                    />
                </DoubleBlindModalEntry>
            </DoubleBlindModal>
        )
    }
}
