import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'
import get from 'lodash-es/get'

import {ReactComponent as IconRun} from 'ipmp-react-ui/icons/run.svg'
import {ReactComponent as IconNotAvailable} from 'ipmp-react-ui/icons/not-available.svg'
import Spinner from 'ipmp-react-ui/Spinner'

import Video from 'components/Video'

class CameraList extends PureComponent {
    static propTypes = {
        cameras: PropTypes.array,
        onStartRecord: PropTypes.func,
        isRequestAllowed: PropTypes.bool,
        isExportAllowed: PropTypes.bool,
        device: PropTypes.object,
        feature: PropTypes.object,
    }

    state = {active: 0}

    showCamera(active) {
        this.setState({active})
    }

    startRecordVideo = () => {
        const {cameras, onStartRecord} = this.props
        const video = cameras && cameras[this.state.active]

        if (video && video.camera) {
            onStartRecord && onStartRecord(video.camera)
        }
    }

    renderCameraIcon(process, frames) {
        if (process && process.isRunning) {
            return <Spinner />
        }

        if (!frames || frames.length === 0) {
            return (
                <IconNotAvailable className="cameraList-item-image-icon cameraList-item-image-icon--notAvailable" />
            )
        }

        return <IconRun className="cameraList-item-image-icon" />
    }

    renderCamera = ({preview, location, present, zone, process, frames}, index) => {
        return (
            <a
                key={'preview$' + zone}
                onClick={() => this.showCamera(index)}
                className={classes('cameraList-item', {
                    'cameraList-item--active': index === this.state.active,
                })}
            >
                <div className="cameraList-item-image">
                    <div
                        className="cameraList-item-image-src"
                        style={{backgroundImage: `url(${preview})`}}
                    />
                    {this.renderCameraIcon(process, frames)}
                </div>

                <strong className="cameraList-item-title">{location}</strong>
                <span className="cameraList-item-description">{zone}</span>
            </a>
        )
    }

    render() {
        const {
            device,
            onStartRecord,
            isRequestAllowed,
            isExportAllowed,
            fallbackTrait = {},
            feature,
        } = this.props
        const cameras = this.props.cameras || []
        const video = cameras[this.state.active]
        const trait = get(device, 'traits.vod', false) || fallbackTrait

        return (
            <div className="cameraList">
                {video && (
                    <Video
                        className="card"
                        key={video.zone}
                        {...video}
                        title={video.location}
                        onStartRecord={onStartRecord ? this.startRecordVideo : null}
                        isRequestAllowed={isRequestAllowed}
                        isExportAllowed={isExportAllowed}
                        trait={trait}
                        feature={feature}
                    />
                )}

                {cameras && cameras.length > 1 && (
                    <div className="cameraList-items">
                        {cameras.map(this.renderCamera)}
                        <div className="cameraList-item cameraList-item--stub" />
                        <div className="cameraList-item cameraList-item--stub" />
                        <div className="cameraList-item cameraList-item--stub" />
                    </div>
                )}
            </div>
        )
    }
}

export default CameraList
