import React, {Fragment, Component} from 'react'
import withProps from 'containers/withProps'

import Page from 'ipmp-react-ui/Page'

import InteractiveUsersBar from './InteractiveUsersBar'
import InteractiveUsersSearch from './InteractiveUsersSearch'
import InteractiveUsersTable from './InteractiveUsersTable'

import InteractiveUserBlind from './InteractiveUserBlind'

class InteractiveUsersContent extends Component {
    state = {
        interactiveUser: null,
    }

    onOpenBlind = (interactiveUser) => this.setState({interactiveUser})

    onCloseBlind = () => this.setState({interactiveUser: null})

    render() {
        const {interactiveUser} = this.state

        return (
            <Fragment>
                <InteractiveUserBlind
                    interactiveUser={interactiveUser}
                    onClose={this.onCloseBlind}
                />
                <InteractiveUsersTable {...this.props} onRowClick={this.onOpenBlind} />
            </Fragment>
        )
    }
}

export default withProps({
    Bar: InteractiveUsersBar,
    Top: InteractiveUsersSearch,
    Content: InteractiveUsersContent,
})(Page)
