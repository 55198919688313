import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {downloadLog} from 'api/panel/logs'
import {fetch, refresh, more} from 'modules/panels/logs/actions'

export default function () {
    return connect(
        (state, {panel}) => ({
            ...state.panels.logs[panel.id],
        }),
        (dispatch, {panel, mode}) =>
            bindActionCreators(
                {
                    fetch: () => fetch(panel.id, mode),
                    refresh: () => refresh(panel.id, mode),
                    download: () => downloadLog(panel.id, mode),
                    more: () => more(panel.id, mode),
                },
                dispatch
            )
    )
}
