import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import {CardActions, CardContent, CardHeader, CardClose} from 'ipmp-react-ui/Card'
import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Modal from 'ipmp-react-ui/Modal'
import Portal from 'ipmp-react-ui/Portal'
import CardMessage from 'ipmp-react-ui/CardMessage'

import {__} from 'utils/i18n'
import stopPropagation from 'utils/stopPropagation'

export default class ModalCardForm extends Component {
    static propTypes = {
        wide: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([2, 3])]),
        className: PropTypes.string,
        header: PropTypes.node,
        isLoading: PropTypes.bool,
        editable: PropTypes.bool,
        showDismiss: PropTypes.bool,
        submitLabel: PropTypes.string,
        dissmissLabel: PropTypes.string,
        onSubmit: PropTypes.func.isRequired,
        confirmOnDismiss: PropTypes.bool,
        confirmOnSubmit: PropTypes.bool,
        hide: PropTypes.func,
    }

    static defaultProps = {
        editable: true,
        showDismiss: true,
        confirmOnDismiss: false,
    }

    state = {
        changed: false,
        showDismissConfirmation: false,
        showSubmitConfirmation: false,
        submiteData: null,
    }

    dismiss = (e) => {
        e && e.preventDefault()

        if (this.isNeedDismissConfirmation()) {
            this.setState({showDismissConfirmation: true})
        } else {
            this.props.hide && this.props.hide(e)
        }
    }

    onChange = (...args) => {
        this.setState({
            changed: true,
        })

        this.props.onChange && this.props.onChange(...args)
    }

    isNeedDismissConfirmation() {
        const {confirmOnDismiss} = this.props
        return confirmOnDismiss && this.state.changed
    }

    isNeedSubmitConfirmation() {
        const {confirmOnSubmit} = this.props
        return confirmOnSubmit
    }

    agreeDismiss = () => this.props.hide && this.props.hide()

    cancelDismiss = () => {
        this.setState({showDismissConfirmation: false})
    }

    submit = (data) => {
        if (this.isNeedSubmitConfirmation()) {
            this.setState({
                showSubmitConfirmation: true,
                submiteData: data,
            })
        } else {
            this.props.onSubmit && this.props.onSubmit(data)
        }
    }

    agreeSubmit = () => {
        this.props.onSubmit && this.props.onSubmit(this.state.submiteData)
        this.props.hide && this.props.hide()
    }

    cancelSubmit = () => {
        this.setState({showSubmitConfirmation: false})
    }

    render() {
        const {
            header,
            hide,
            className,
            children,
            submitLabel,
            dissmissLabel,
            showDismiss,
            wide,
            editable,
            ...props
        } = this.props

        const {showDismissConfirmation, showSubmitConfirmation} = this.state

        delete props.confirmOnDismiss

        return (
            <Modal onClose={this.dismiss}>
                <Form
                    {...props}
                    onSubmit={this.submit}
                    onChange={this.onChange}
                    className={classes('card', className, {
                        'card--wide': wide === true,
                        'card--wide-2': wide === 2,
                        'card--wide-3': wide === 3,
                    })}
                >
                    {hide && <CardClose onClick={this.dismiss} />}
                    {header && <CardHeader>{header}</CardHeader>}

                    <CardContent>{children}</CardContent>

                    <CardActions>
                        {hide && showDismiss && (
                            <Button borderless shortcut="esc" onClick={this.dismiss}>
                                {dissmissLabel || __('Dismiss')}
                            </Button>
                        )}

                        {editable && (
                            <Button primary disabled={props.isLoading} type="submit">
                                {submitLabel || __('Save')}
                            </Button>
                        )}
                    </CardActions>
                </Form>

                {showDismissConfirmation && (
                    <Portal onClick={stopPropagation} key="portal">
                        <Modal onClose={this.dismiss}>
                            <CardMessage
                                className="card--narrow"
                                title={__('Closing form')}
                                message={__(
                                    "All data will be lost. Do you wan't to proceed?"
                                )}
                                positive={__('Confirm')}
                                negative={__('Cancel')}
                                onPositive={this.agreeDismiss}
                                onNegative={this.cancelDismiss}
                                onClose={this.cancelDismiss}
                            />
                        </Modal>
                    </Portal>
                )}
                {showSubmitConfirmation && (
                    <Portal onClick={stopPropagation} key="portal">
                        <Modal onClose={this.dismiss}>
                            <CardMessage
                                className="card--narrow"
                                title={__('Submit form')}
                                message={__(
                                    "All data will be submite. Do you wan't to proceed?"
                                )}
                                positive={__('Confirm')}
                                negative={__('Cancel')}
                                onPositive={this.agreeSubmit}
                                onNegative={this.cancelSubmit}
                                onClose={this.cancelSubmit}
                            />
                        </Modal>
                    </Portal>
                )}
            </Modal>
        )
    }
}
