import {createAction} from 'redux-actions'

export const send = createAction(
    'FORM/SEND',
    (name, meta) => name,
    (name, meta) => meta
)

export const getBatchId = createAction('FORM/GET_BATCH_ID')

export const receive = createAction(
    'FORM/RECEIVE',
    (name, promise) => {
        return promise
            .then((result) => ({name, success: true, result}))
            .catch((error) => {
                const {message, errors, details} = error
                return {name, error: message || details, errors, originalError: error}
            })
    },
    (name, promise, meta) => meta
)
