import axios from 'axios'
import {stringify} from 'query-string'

const instance = axios.create({
    baseURL: '/api/',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
    },
    timeout: 30000,
})

export function get(url, data, options = {}) {
    return instance.get(url + (data ? '?' + stringify(data) : ''), options)
}

export function post(url, data, options = {}) {
    return instance.post(url, data, options)
}

export function del(url, data, options = {}) {
    return instance.delete(url, {params: data}, options)
}

export default instance
