import {cancel, fork, take, call, race, delay} from 'redux-saga/effects'

export default function* createListPollerSaga(listActions, interval, iteratee) {
    while (true) {
        const action = yield take(listActions.startPoll)

        const pollTask = yield fork(
            poller,
            interval,
            iteratee,
            [
                listActions.setStart,
                listActions.setQuery,
                listActions.setFilters,
                listActions.clearFilters,
                listActions.addFilters,
                listActions.removeFilters,
            ],
            action
        )

        yield take(listActions.stopPoll)
        yield cancel(pollTask)
    }
}

export function* poller(interval, iteratee, cancelActions, ...args) {
    while (true) {
        if (Number.isInteger(interval)) {
            yield delay(interval)
        } else {
            yield call(interval, ...args)
        }
        yield race([call(iteratee, ...args), take(cancelActions)])
    }
}
