import React, {PureComponent} from 'react'

import Menu, {MenuLink} from 'ipmp-react-ui/Menu'
import DropDown, {ALIGN_TOP} from 'ipmp-react-ui/DropDown'
import Definition from 'ipmp-react-ui/Definition'
import {ReactComponent as IconCompletedAll} from 'ipmp-react-ui/icons/check-all.svg'
import batchStatusTitle from 'constants/batchesStatuses'

import CircularProgress from 'ui/CircularProgress'
import {__, __n} from 'utils/i18n'
import path from 'utils/path'
import stopPropagation from 'utils/stopPropagation'

export default class BatchStatusCell extends PureComponent {
    static defaultProps = {
        pathToRedirection: 'batches.runners',
        idMapper: (id) => ({id}),
    }

    progressIcon = (props) => {
        const {stats, processCount} = this.props.row
        const {pending} = stats || {}

        return (
            <CircularProgress
                value={((processCount - pending) / processCount) * 100}
                {...props}
            />
        )
    }

    renderTrigger() {
        const {stats} = this.props.row
        const {pending} = stats || {}

        if (pending > 0) {
            return <Definition Icon={this.progressIcon} title={__('In progress')} />
        }

        return <Definition Icon={IconCompletedAll} title={__('Completed')} />
    }

    renderMenuItem = (type) => {
        const {pathToRedirection, idMapper} = this.props
        const {id} = this.props.row
        const count = this.props.row.stats[type]
        const to = `${path(pathToRedirection, idMapper(id))}?status=${type}`

        if (count > 0) {
            return (
                <MenuLink key={type} to={to}>
                    <Definition
                        title={batchStatusTitle(type)}
                        detail={__n('%d panel', '%d panels', count)}
                    />
                </MenuLink>
            )
        }

        return null
    }

    render() {
        return (
            <div onClick={stopPropagation}>
                <DropDown trigger={this.renderTrigger()} align={ALIGN_TOP}>
                    <Menu>
                        {Object.keys(this.props.row.stats)
                            .filter((type) => type !== 'pending')
                            .map(this.renderMenuItem)}
                    </Menu>
                </DropDown>
            </div>
        )
    }
}
