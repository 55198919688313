import {takeEvery, all, call, put} from 'redux-saga/effects'
import uniqBy from 'lodash-es/uniqBy'

import * as api from 'api/panel/firmware'
import {fetch, receive, upgrade} from './actions'
import {PROCESS_TYPE_NEO_UPGRADE} from 'constants/processTypes'
import {generateBatchForOneProcess} from 'modules/batches/manager/generateBatch'

import {takeEveryProcessComplete} from 'modules/processes/manager/takeProcess'
import {snackShow} from 'modules/snacks'
import {hide} from 'modules/modals/actions'

export default function* () {
    yield all([
        takeEvery(fetch, watchFetch),
        takeEvery(upgrade, watchUpgrade),
        takeEveryProcessComplete(PROCESS_TYPE_NEO_UPGRADE, watchProcessComplete),
    ])
}

function* watchFetch({payload: {panelId}}) {
    try {
        const {appliances, runner} = yield call(api.fetchList, panelId)

        yield put(
            receive(
                {
                    appliances,
                    runner,
                },
                panelId
            )
        )
    } catch (error) {
        yield put(receive(error, panelId))
    }
}

function* watchUpgrade({payload}) {
    const {panelId, packages, timeout, failOnArmedState} = payload
    const {batchId} = yield generateBatchForOneProcess(PROCESS_TYPE_NEO_UPGRADE, panelId)

    try {
        const runner = yield call(
            api.update,
            panelId,
            uniqBy(packages, 'packageName'),
            timeout,
            failOnArmedState,
            batchId
        )
        yield put(hide())
        yield put(
            receive(
                {
                    runner,
                },
                panelId
            )
        )
    } catch ({message}) {
        yield put(receive({runner: null}, panelId))
        yield put(snackShow(message))
    }
}

function* watchProcessComplete({panelId}) {
    yield put(fetch(panelId))
}
