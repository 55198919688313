import {bindActionCreators, compose} from 'redux'

import {connect} from 'react-redux'

import {
    addFilters,
    removeFilters,
    clearFilters,
    setQuery,
    fetch,
    fetchSuggests,
} from 'modules/remoteInspections/list/actions'

import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'
import withProps from 'containers/withProps'

import Search from 'components/Search/Search'
import DateFilter from 'components/Search/Filters/DateFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'

import list from 'permissions/remoteInspections/list'

import __ from 'utils/i18n'
import {showSaveSearchModal} from 'modules/modals/actions'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new SuggestFilter('serial', __('Panel Id')),
            new SuggestFilter('account', __('Account')),
            new SuggestFilter('group', __('Group')),

            new DateFilter('last', __('Last RRI')),
            new DateFilter('next', __('Next RRI'), 'future'),

            new ValuesFilter('isEmailSent', __('Email Sent'), {
                true: __('Yes'),
                false: __('No'),
            }),

            new ValuesFilter('isReviewed', __('Reviewed'), {
                true: __('Yes'),
                false: __('No'),
            }),
        ],
    })),
    connect(
        ({remoteInspections: {list, suggests}}, {match}) => ({
            key: match.params.scope,
            selected: list.filters,
            query: list.query,
            suggests: list.suggests,
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    apply: fetch,
                    onSelect: addFilters,
                    onDeselect: removeFilters,
                    onClear: clearFilters,
                    onQuery: setQuery,
                    onSuggest: fetchSuggests,
                    onSaveSearch: (filters) =>
                        showSaveSearchModal('remoteInspections', filters),
                },
                dispatch
            )
    )
)(Search)
