import React, {PureComponent} from 'react'
import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'

import PanelFieldSet, {validation} from './Fieldset/Panel'
import {compose} from 'redux'
import withForm from 'containers/withForm'
import {connect} from 'react-redux'
import {editPanelInfo} from 'modules/forms/handlers'

export class EditPanelInfo extends PureComponent {
    handle = (data) => {
        const {handle, panelId} = this.props
        data.groupId = parseInt(data.groupId)
        handle(panelId, data)
    }

    render() {
        const {panel, ...props} = this.props

        const rules = validation[panel.vendor]

        return (
            <ModalCardForm
                onSubmit={this.handle}
                confirmOnDismiss
                rules={rules}
                header={__('Edit Panel')}
                {...props}
            >
                <PanelFieldSet data={panel} />
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(({panels}, {panelId}) => ({
        panel: panels.store.byIds[panelId] || {},
    })),
    withForm(editPanelInfo)
)(EditPanelInfo)
