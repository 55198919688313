import React from 'react'
import {compose} from 'redux'

import Table from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'
import {withAutoFilters} from 'containers/withSearchScopeLoader'
import withUpgradeStatusRunners from 'containers/withUpgradeStatusRunners'
import withProps from 'containers/withProps'
import withLifeCycle from 'containers/withLifeCycle'
import withLoader from 'containers/withLoader'
import withSelection from 'containers/withSelection'
import UpgradeStatusRetryButton from './Table/UpgradeStatusRetryButton'

import {columns} from 'pages/Batches/Runners/RunnersTable'
import Definition from 'ipmp-react-ui/Definition'
import processType from 'constants/processType'
import Packages from 'components/UpgradeStatus/Packages'

function mutateColumns(columns) {
    const columnsWithoutStopProcessButton = columns.slice(0, -1)
    columnsWithoutStopProcessButton[2] = {
        ...columnsWithoutStopProcessButton[2],
        render: ({batchId, params: {packages}, type, description}) => (
            <Packages packages={packages}>
                <Definition title={processType(type)} detail={description} />
            </Packages>
        ),
    }

    return columnsWithoutStopProcessButton
}

const correctColumns = mutateColumns(columns)

const tableColumns = [
    ...correctColumns,
    {
        width: 50,
        fixed: true,
        tooltip: false,
        align: 'right',
        render: ({isFailed, id}) => {
            if (isFailed) {
                return <UpgradeStatusRetryButton id={id} />
            }

            return null
        },
    },
]

export default compose(
    withUpgradeStatusRunners(),
    withSelection(),
    withProps(() => ({
        columns: tableColumns,
        fullHeight: true,
        emptyMessage: () => __('No batch runners'),
    })),
    withLifeCycle({
        onMount({startPoll}) {
            startPoll()
        },

        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withAutoFilters(),
    withLoader(({init, filters}) => {
        return init(null, 'PUSH', filters)
    })
)(Table)
