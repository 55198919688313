import has from 'lodash-es/has'
import alarmSound from 'assets/alerts/alert.mp3'

export const ALARM_SOUND = 'alarm'

const sounds = {
    ALARM_SOUND: new Audio(alarmSound),
}

export default {
    play(type = ALARM_SOUND) {
        if (has(sounds, [type, 'play'])) {
            sounds[type].play()
        }
    },
}
