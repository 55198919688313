import React from 'react'
import PropTypes from 'prop-types'

export const InteractiveUserInfoDefinition = ({name, children}) => (
    <dl className="interactiveUserInfo-item">
        <dt className="interactiveUserInfo-item-title">{name}</dt>
        <dd className="interactiveUserInfo-item-definition">{children}</dd>
    </dl>
)

InteractiveUserInfoDefinition.propTypes = {
    name: PropTypes.string.isRequired,
}

export const InteractiveUserInfo = ({children}) => (
    <div className="interactiveUserInfo">{children}</div>
)

export default InteractiveUserInfo
