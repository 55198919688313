import React, {Component} from 'react'
import PropTypes from 'prop-types'

import FilterDefinition from 'components/Search/Filters/Filter'

import Button from 'ipmp-react-ui/Button'
import Spinner from 'ipmp-react-ui/Spinner'
import __ from 'utils/i18n'

export default class SearchBoard extends Component {
    static propTypes = {
        filters: PropTypes.arrayOf(PropTypes.instanceOf(FilterDefinition)).isRequired,
        selected: PropTypes.array,
        suggests: PropTypes.object,
        onSelect: PropTypes.func.isRequired,
        onDeselect: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onSuggest: PropTypes.func,
        onSaveSearch: PropTypes.func,
    }

    componentDidMount() {
        const {filters, onSuggest} = this.props

        const names = filters
            .filter((filter) => filter.hasSuggests)
            .map((filter) => filter.name)

        if (names.length && onSuggest) {
            onSuggest(names)
        }
    }

    renderFilters() {
        const {filters, selected, suggests, onSelect, onDeselect, onSuggest} = this.props

        const suggestFilters = filters.filter((filter) => filter.hasSuggests)

        const isLoading =
            suggestFilters.length > 0 &&
            suggestFilters.every(
                (filter) => !suggests[filter.name] || suggests[filter.name].isLoading
            )

        if (isLoading) {
            return <Spinner />
        }

        return filters.map((filter) => (
            <filter.Component
                key={filter.name}
                filter={filter}
                suggest={suggests && suggests[filter.name]}
                selected={selected}
                onSelect={onSelect}
                onDeselect={onDeselect}
                onSuggest={onSuggest}
            />
        ))
    }

    render() {
        const {selected, onSaveSearch, onSubmit} = this.props

        return (
            <div className="search-popup">
                <div className="search-filters">{this.renderFilters()}</div>

                <div className="card-actions">
                    {onSaveSearch && selected && selected.length > 0 && (
                        <Button borderless onClick={onSaveSearch}>
                            {__('Save current search')}
                        </Button>
                    )}

                    <Button primary onClick={onSubmit}>
                        {__('Search')}
                    </Button>
                </div>
            </div>
        )
    }
}
