import DateFilter from 'components/Search/Filters/DateFilter'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    setQuery,
} from 'modules/interactiveUsers/list/actions'

import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import Search from 'components/Search/Search'

import __ from 'utils/i18n'

export default compose(
    connect(
        (state) => ({
            filters: [
                new ValuesFilter('status', __('Status'), {
                    verified: __('Active'),
                    suspended: __('Suspended'),
                }),

                new DateFilter('createdAt', __('Created')),
            ],
            query: state.interactiveUsers.list.query,
            selected: state.interactiveUsers.list.filters,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    apply: fetch,
                    onQuery: setQuery,
                    onSelect: addFilters,
                    onClear: clearFilters,
                    onDeselect: removeFilters,
                },
                dispatch
            )
    )
)(Search)
