import React, {PureComponent} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import Input from 'ipmp-react-ui/Input'

import withForm from 'containers/withForm'
import {setSystemSettings} from 'modules/forms/handlers'

import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'

class RotationSettings extends PureComponent {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        data: PropTypes.shape({
            maxEventAge: PropTypes.number,
            maxProcessAge: PropTypes.number,
            maxReportsAge: PropTypes.number,
        }),
    }

    handle = (data) => {
        const {handle} = this.props
        handle(data)
    }

    rules = {
        maxEventAge: {
            presence: {},
            numericality: {
                onlyInteger: true,
                greaterThan: 0,
                lessThan: 367,
            },
        },
        maxProcessAge: {
            presence: {},
            numericality: {
                onlyInteger: true,
                greaterThan: 0,
                lessThan: 367,
            },
        },
        maxReportsAge: {
            presence: {},
            numericality: {
                onlyInteger: true,
                greaterThan: 0,
                lessThan: 367,
            },
        },
    }

    render() {
        const {data, ...props} = this.props

        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={this.rules}
                header={__('Edit Rotation Settings')}
                confirmOnDismiss
                {...props}
            >
                <Input
                    label={__('Events Age [DAYS]')}
                    name="maxEventAge"
                    defaultValue={data.maxEventAge}
                />
                <Input
                    label={__('Events Process [DAYS]')}
                    name="maxProcessAge"
                    defaultValue={data.maxProcessAge}
                />
                <Input
                    label={__('Events Reports [DAYS]')}
                    name="maxReportsAge"
                    defaultValue={data.maxReportsAge}
                />
            </ModalCardForm>
        )
    }
}

export default compose(withForm(setSystemSettings.Rotation))(RotationSettings)
