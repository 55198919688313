import {connect} from 'react-redux'
import {compose} from 'redux'

import list from 'permissions/events/list'

import {selectEventsBySerialListRows} from 'modules/events/bySerialList/selectors'

import {withPermission, withRejection} from 'containers/withPermission'
import EventsTable from 'components/Table/EventsTable'

export default compose(
    withPermission({isAllowed: list}),
    withRejection(),
    connect((state, {selected}) => {
        return {
            rows: selectEventsBySerialListRows(state),
            active: state.events.store.byIds[selected],
        }
    })
)(EventsTable)
