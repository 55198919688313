import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import CardMessage from 'ipmp-react-ui/CardMessage'
import Modal from 'ipmp-react-ui/Modal'

class Confirmation extends Component {
    static propTypes = {
        action: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string.isRequired,
            })
        ),
        dispatch: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,

        messages: PropTypes.shape({
            title: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired,
            positive: PropTypes.string,
            negative: PropTypes.string,
        }).isRequired,
    }

    handle = () => {
        const {hide, action, dispatch} = this.props

        hide()
        action.forEach(dispatch)
    }

    render() {
        const {messages, hide} = this.props

        return (
            <Modal onClose={hide}>
                <CardMessage
                    title={messages.title}
                    message={messages.message}
                    positive={messages.positive}
                    negative={messages.negative}
                    onClose={hide}
                    onNegative={hide}
                    onPositive={this.handle}
                />
            </Modal>
        )
    }
}

export default connect()(Confirmation)
