import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconPrev} from 'ipmp-react-ui/icons/arrow-prev.svg'
import {ReactComponent as IconNext} from 'ipmp-react-ui/icons/arrow-next.svg'
import {ReactComponent as IconDownload} from 'ipmp-react-ui/icons/download.svg'
import {ReactComponent as IconTimer} from 'ipmp-react-ui/icons/timer.svg'
import {ReactComponent as IconNotAvailable} from 'ipmp-react-ui/icons/not-available.svg'
import {ReactComponent as IconRun} from 'ipmp-react-ui/icons/run.svg'
import classes from 'classnames'
import Spinner from 'ipmp-react-ui/Spinner'
import Button from 'ipmp-react-ui/Button'
import __ from 'utils/i18n'
import VideoModal from 'ipmp-react-ui/VideoModal'
import {humanTime} from 'ipmp-react-ui/humanTime'
import Error from 'ipmp-react-ui/Error'
import {
    DEFAULT_VIDEO_FORMAT,
    REQUEST_ALLOWED_TYPE_WITH_TIME_WINDOW,
    REQUEST_ALLOWED_TYPE_ANY,
    TRAIT_VOD_CONDITION_ALLOWED,
    TRAIT_VOD_CONDITION_VOD_DISABLED,
    TRAIT_VOD_CONDITION_NOT_ALARM_CONDITION,
    TRAIT_VOD_CONDITION_NOT_AWAY_CONDITION,
    REQUEST_ALLOWED_TYPE_CONDITIONAL,
} from 'constants/videoOnDemand'
import moment from 'moment'
import CircularProgress from 'ui/CircularProgress'

const PREVIEW_THUMB_OFFSET = 44

export default class Video extends Component {
    static propTypes = {
        frames: PropTypes.arrayOf(PropTypes.string),
        video: PropTypes.shape({
            mp4: PropTypes.string,
            webm: PropTypes.string,
        }),
        time: PropTypes.string,
        className: PropTypes.string,
        title: PropTypes.string,
        arrived: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        rate: PropTypes.number,
        duration: PropTypes.number,
        onStartRecord: PropTypes.func,
        process: PropTypes.shape({
            isRunning: PropTypes.bool,
            isFailed: PropTypes.bool,
            message: PropTypes.string,
        }),
        isExportAllowed: PropTypes.bool,
        isRequestAllowed: PropTypes.bool,
        feature: PropTypes.shape({
            isEnabled: PropTypes.bool,
            vodType: PropTypes.string,
            vodRequestAllowedType: PropTypes.string,
        }),
        trait: PropTypes.shape({
            available_till: PropTypes.number,
            available_from: PropTypes.number,
            condition: PropTypes.oneOf([
                TRAIT_VOD_CONDITION_ALLOWED,
                TRAIT_VOD_CONDITION_VOD_DISABLED,
                TRAIT_VOD_CONDITION_NOT_ALARM_CONDITION,
                TRAIT_VOD_CONDITION_NOT_AWAY_CONDITION,
            ]),
        }),
    }

    static defaultProps = {
        rate: 0.46,
        frames: [],
    }

    loaded = []

    playing = false

    state = {
        slide: 0,
        time: 0,
        progress: 0,
        loading: true,
    }

    getMappedFrames = () => {
        const {frames, time} = this.props

        return frames.map((src) => `${src}?time=${encodeURIComponent(time)}`)
    }

    getDuration() {
        const {duration, frames, rate} = this.props
        return duration || frames.length * rate
    }

    componentDidMount() {
        this.starSlidePlaying()
        window.addEventListener('blur', this.stopSlidePlaying)
    }

    componentWillUnmount() {
        this.stopSlidePlaying()
        window.removeEventListener('blur', this.stopSlidePlaying)
    }

    static getDerivedStateFromProps({frames, duration, rate}, prevState) {
        if (frames && frames.length > 0) {
            const slide = Math.min(frames.length, Math.max(0, prevState.slide || 0))
            const progress = frames.length > 1 ? slide / (frames.length - 1) : 0

            if (prevState.slide !== slide) {
                return {
                    slide,
                    progress,
                    time: progress * (duration || frames.length * rate) * 1000,
                }
            }
        }
        return {}
    }

    animate = () => {
        if (!this.playing) {
            return
        }

        const now = Date.now()

        if (this.looptime) {
            const duration = this.getDuration() * 1000
            const dt = now - this.looptime
            const time = this.state.time + dt
            const progress = time / duration
            const slide = Math.ceil((this.props.frames.length - 1) * progress)

            if (time > duration) {
                this.setState({
                    time: 0,
                    progress: 0,
                    slide: 0,
                })

                this.playing = false
            } else {
                this.setState({time, progress, slide})
            }
        }

        this.looptime = now

        requestAnimationFrame(this.animate)
    }

    starSlidePlaying = () => {
        if (this.state.fullScreen) {
            return
        }

        this.playing = true
        this.looptime = null
        this.animate()
    }

    stopSlidePlaying = () => {
        this.looptime = null
        this.playing = false
    }

    imageLoaded = (e) => {
        this.loaded.push(e.target.src.replace(/https?:\/\/[^/]+\//, '/'))

        const notReadyFrames = this.getMappedFrames().filter(
            (src) => !this.loaded.includes(src)
        )

        this.setState({loading: notReadyFrames.length > 0})
    }

    moveSlide(direction = 1) {
        this.stopSlidePlaying()

        this.setState(({slide}) => {
            const length = this.props.frames.length
            const newSlide = (slide + length + direction) % length
            const progress = newSlide / (length - 1)

            return {
                progress,
                time: progress * this.getDuration() * 1000,
                slide: newSlide,
            }
        })
    }

    onProgressMouseLeave = (e) => {
        this.setState({preview: null})
    }

    onProgressMouseDown = (e) => {
        e.preventDefault()
        this.stopSlidePlaying()

        const rect = this.refs.bar.getBoundingClientRect()
        const progress = (e.pageX - rect.left) / rect.width
        const slide = Math.round(progress * (this.props.frames.length - 1))

        this.setState({
            progress,
            slide,
            time: progress * this.getDuration() * 1000,
        })

        this.drag = true

        return false
    }

    onProgressMouseUp = (e) => {
        this.drag = false
    }

    onProgressMouseMove = (e) => {
        e.preventDefault()

        const rect = this.refs.bar.getBoundingClientRect()
        const progress = e.pageX - rect.left
        const preview = {
            offset: Math.max(
                PREVIEW_THUMB_OFFSET,
                Math.min(rect.width - PREVIEW_THUMB_OFFSET, progress)
            ),
            progress,
            slide: Math.round((progress / rect.width) * (this.props.frames.length - 1)),
        }
        this.setState({preview})

        if (this.drag) {
            this.onProgressMouseDown(e)
        }
    }

    nextSlide = () => this.moveSlide()

    prevSlide = () => this.moveSlide(-1)

    showFullScreenVideo = () => {
        this.stopSlidePlaying()
        this.setState({fullScreen: true})
    }

    hideFullScreenVideo = () => {
        this.setState({fullScreen: false})
    }

    renderPlayButton() {
        const {process} = this.props

        if (process && process.isRunning) {
            return <Spinner />
        }

        return (
            <a className="video-nav video-nav--play" onClick={this.showFullScreenVideo}>
                <span className="video-nav-circle">
                    <IconRun className="video-nav-icon" />
                </span>
            </a>
        )
    }

    renderVideoPlayerContent() {
        const {frames, process} = this.props

        if (!process && !frames.length) {
            return <IconNotAvailable className="video-player-icon" />
        }

        if (process && process.isFailed && !frames.length) {
            return <Error title={__('Video failed')} message={process.errorMessage} />
        }

        if (this.state.loading) {
            return <Spinner />
        }

        return (
            <div>
                {frames.length > 1 && (
                    <a className="video-nav video-nav--next" onClick={this.nextSlide}>
                        <IconNext className="video-nav-icon" />
                    </a>
                )}

                {frames.length > 1 && (
                    <a className="video-nav video-nav--prev" onClick={this.prevSlide}>
                        <IconPrev className="video-nav-icon" />
                    </a>
                )}

                {this.renderPlayButton()}
            </div>
        )
    }

    renderProgress() {
        const frames = this.getMappedFrames()
        const {preview, progress} = this.state

        if (!frames || frames.length === 0) {
            return <div className="video-progress" />
        }

        return (
            <div
                className="video-progress"
                ref="bar"
                onMouseDown={this.onProgressMouseDown}
                onMouseUp={this.onProgressMouseUp}
                onMouseMove={this.onProgressMouseMove}
                onMouseLeave={this.onProgressMouseLeave}
            >
                {frames.length > 0 && (
                    <div
                        className="video-progress-bar"
                        style={{width: 100 * progress + '%'}}
                    >
                        <div className="video-progress-knob" />
                    </div>
                )}

                {preview && (
                    <div
                        className="video-progress-bar video-progress-bar--preview"
                        style={{width: preview.progress}}
                    >
                        <div className="video-thumb" style={{left: preview.offset}}>
                            <img
                                className="video-thumb--image"
                                src={frames[preview.slide]}
                                alt=""
                            />
                        </div>
                    </div>
                )}
            </div>
        )
    }

    getTime() {
        const {time, process} = this.props
        return time || (process && process.started)
    }

    renderTime() {
        const time = this.getTime()

        return (
            <div className="video-time">
                {time ? '' + humanTime(time) : __('No video')}
            </div>
        )
    }

    getDownloadLink = () => {
        const {video} = this.props
        const formats = Object.keys(video)
        const format = formats.includes(DEFAULT_VIDEO_FORMAT)
            ? DEFAULT_VIDEO_FORMAT
            : formats.shift()

        return video[format]
    }

    getRequestIcon() {
        const {available_till: availableTill = 0, available_since: availableSince = 0} =
            this.props.trait || {}

        const {vodRequestAllowedType = REQUEST_ALLOWED_TYPE_ANY} =
            this.props.feature || {}

        if (vodRequestAllowedType != REQUEST_ALLOWED_TYPE_WITH_TIME_WINDOW) {
            return <div className="video-record" />
        }

        if (!(availableSince > 0) || !this.getIsAvailable()) {
            return <IconTimer className="btn-icon btn-icon--offset" />
        }
        const progress =
            ((moment().unix() - availableSince) / (availableTill - availableSince)) * 100
        return <CircularProgress className="video-circular" value={progress} />
    }

    getIsAvailable() {
        // See: PMN-9013
        return true

        // const {vodRequestAllowedType = REQUEST_ALLOWED_TYPE_ANY} =
        // this.props.feature || {}
        //
        // switch (vodRequestAllowedType) {
        //    case REQUEST_ALLOWED_TYPE_ANY:
        //        return true
        //    case REQUEST_ALLOWED_TYPE_WITH_TIME_WINDOW:
        //        return this.getIsAvailableByTraitAvailableTill()
        //    case REQUEST_ALLOWED_TYPE_CONDITIONAL:
        //        return this.getIsAvailableByTraitCondition()
        // }
    }

    getIsAvailableByTraitCondition = () => {
        const {condition} = this.props.trait || {}
        switch (condition) {
            case TRAIT_VOD_CONDITION_ALLOWED:
                return this.getIsAvailableByTraitAvailableTill()
            case TRAIT_VOD_CONDITION_VOD_DISABLED:
            case TRAIT_VOD_CONDITION_NOT_ALARM_CONDITION:
            case TRAIT_VOD_CONDITION_NOT_AWAY_CONDITION:
                return false
        }
    }

    getIsAvailableByTraitAvailableTill = () => {
        const {available_till: availableTill = 0} = this.props.trait || {}

        if (availableTill > 0) {
            return availableTill > moment().unix()
        }
        return false
    }

    getIsAvailableText = () => {
        const {condition} = this.props.trait || {}
        const {vodRequestAllowedType = REQUEST_ALLOWED_TYPE_ANY} =
            this.props.feature || {}

        if (vodRequestAllowedType === REQUEST_ALLOWED_TYPE_WITH_TIME_WINDOW) {
            return __('Requesting video is available only when alarm occurred')
        }

        if (vodRequestAllowedType === REQUEST_ALLOWED_TYPE_CONDITIONAL) {
            switch (condition) {
                case TRAIT_VOD_CONDITION_VOD_DISABLED:
                    return __('Requesting video is disabled')
                case TRAIT_VOD_CONDITION_NOT_ALARM_CONDITION:
                    return __('Requesting video is available only when alarm occurred')
                case TRAIT_VOD_CONDITION_NOT_AWAY_CONDITION:
                    return __('Requesting video is allowed only on away state')
                default:
                    return __('Requesting video is available only when alarm occurred')
            }
        }

        return ''
    }

    renderAvailableTime() {
        if (this.getIsAvailable()) {
            return null
        }
        if (!this.props.isRequestAllowed) {
            return null
        }
        const text = this.getIsAvailableText()
        return <div className="video-available-till">{text}</div>
    }

    renderActions() {
        const {
            video,
            process,
            onStartRecord,
            isRequestAllowed,
            isExportAllowed,
        } = this.props
        const actions = []

        if (isRequestAllowed && onStartRecord) {
            const isAvailable = this.getIsAvailable()
            actions.push(
                <Button
                    key="request"
                    borderless
                    small
                    onClick={onStartRecord}
                    disabled={(process && process.isRunning) || !isAvailable}
                >
                    {this.getRequestIcon()}
                    {__('Request new video')}
                </Button>
            )
        }

        if (isExportAllowed && video) {
            actions.push(
                <Button
                    key="download"
                    borderless
                    small
                    href={this.getDownloadLink()}
                    Icon={IconDownload}
                    download
                    disabled={process && process.isRunning}
                >
                    {__('Download')}
                </Button>
            )
        }

        if (actions.length === 0) {
            return null
        }

        return <div className="video-actions">{actions}</div>
    }

    render() {
        const {video, className, title} = this.props
        const {fullScreen, loading, slide} = this.state
        const frames = this.getMappedFrames()
        return (
            <div className={classes('video', className)}>
                <div className="video-player">
                    {this.renderVideoPlayerContent()}

                    <div className="video-container">
                        {frames.map((src, key) => (
                            <img
                                key={key}
                                src={src}
                                onLoad={this.imageLoaded}
                                onError={this.imageLoaded}
                                className={classes('video-element', {
                                    'video-element--hidden': loading || key !== slide,
                                })}
                            />
                        ))}
                    </div>
                </div>

                {this.renderProgress()}

                <div className="video-definition">
                    <div>
                        <h3 className="video-title">{title}</h3>
                        {this.renderTime()}
                    </div>

                    <div className="video-description">
                        {__(
                            '%d frames, %s seconds',
                            frames.length,
                            this.getDuration().toFixed(1)
                        )}
                    </div>
                </div>

                {this.renderActions()}
                {this.renderAvailableTime()}
                {fullScreen && (
                    <VideoModal
                        onClose={this.hideFullScreenVideo}
                        video={video}
                        frames={frames}
                    />
                )}
            </div>
        )
    }
}
