import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import list from 'permissions/users/list'

import {
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    setQuery,
    fetchSuggests,
} from 'modules/users/list/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'

import DateFilter from 'components/Search/Filters/DateFilter'
import Search from 'components/Search/Search'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'

import __ from 'utils/i18n'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new SuggestFilter('name', __('Name')),
            new SuggestFilter('phone', __('Phone')),
            new SuggestFilter('roleName', __('Role Name')),
            new SuggestFilter('roleCreatorName', __('Role Creator Name')),
            new DateFilter('lastLogin', __('Last Login')),
            new TranslateSuggestFilter('online', __('Online'), {
                online: __('Online'),
                offline: __('Offline'),
            }),
        ],
    })),
    connect(
        ({users: {list}}) => ({
            selected: list.filters,
            query: list.query,
            suggests: list.suggests,
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    apply: fetch,
                    onSelect: addFilters,
                    onQuery: setQuery,
                    onDeselect: removeFilters,
                    onClear: clearFilters,
                    onSuggest: fetchSuggests,
                },
                dispatch
            )
    )
)(Search)
