import React, {Component} from 'react'
import axios from 'axios'
import path from 'assets/custom-logo.svg'

const loader = axios
    .get(path)
    .then((resp) => resp.data.replace(/<!--[\s\S]*?-->/g, '').trim())

export default class Logo extends Component {
    state = {
        content: null,
    }

    constructor(props) {
        super(props)
        loader.then(this.loaded)
    }

    loaded = (content) => this.setState({content})

    content() {
        return {__html: this.state.content}
    }

    render() {
        if (!this.state.content) {
            return (
                <div className="logo">
                    Power
                    {this.props.multiline && <br />}
                    Manage
                </div>
            )
        }

        return (
            <div className="logo logo--custom" dangerouslySetInnerHTML={this.content()} />
        )
    }
}
