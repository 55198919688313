import {takeEvery, all, call, put} from 'redux-saga/effects'

import * as actions from './actions'
import {fetchPanelUsers} from 'api/interactiveUsers'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([takeEvery(actions.fetch, watchFetch)])
}

function* watchFetch({payload: panelId}) {
    try {
        const data = yield call(fetchPanelUsers, {panelId})
        yield put(actions.update({panelId, data}))
        yield put(actions.setLoading(false))
    } catch (error) {
        yield put(actions.setLoading(false))
        yield put(snackShow(error.message))
    }
}
