import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {
    FIRMWARE_UPGRADE_DEFAULT_TIMEOUT,
    FIRMWARE_UPGRADE_MINIMAL_TIMEOUT,
    FIRMWARE_UPGRADE_MAXIMUM_TIMEOUT,
    hasTimeoutAttribute,
} from 'constants/firmware'

import {upgrade as upgradeBatch, upgradeByFilters} from 'modules/firmware/list/actions'
import {upgrade as upgradePanel} from 'modules/panels/firmware/actions'

import ModalCardForm from 'ui/ModalCardForm'
import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'

class FirmwareUpgrade extends Component {
    static propTypes = {
        type: PropTypes.string,
        packages: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
            })
        ),

        handle: PropTypes.func.isRequired,
        hide: PropTypes.func,
    }

    onClose = () => {
        const {hide} = this.props

        return hide()
    }

    onSubmit = ({timeout, failOnArmedState}) => {
        const {handle} = this.props

        return handle(timeout, failOnArmedState === 'on')
    }

    getRules = () => {
        let rules = {}
        if (this.isTimeoutVisible()) {
            rules.timeout = {
                numericality: {
                    greaterThanOrEqualTo: FIRMWARE_UPGRADE_MINIMAL_TIMEOUT,
                    lessThanOrEqualTo: FIRMWARE_UPGRADE_MAXIMUM_TIMEOUT,
                    onlyInteger: true,
                },
            }
        }
        return rules
    }

    isTimeoutVisible = () => {
        const {type, packages} = this.props
        return (
            hasTimeoutAttribute(type) ||
            (Array.isArray(packages) &&
                packages.some(({type}) => hasTimeoutAttribute(type)))
        )
    }

    render() {
        const {isLoading, errors, error} = this.props

        return (
            <ModalCardForm
                isLoading={isLoading}
                header={__('Firmware upgrade')}
                submitLabel={__('Ok')}
                errors={errors}
                error={error}
                hide={this.onClose}
                onSubmit={this.onSubmit}
                rules={this.getRules()}
            >
                {this.isTimeoutVisible() && (
                    <Input
                        label={__('Timeout, seconds')}
                        name="timeout"
                        type="text"
                        maxLength="10"
                        defaultValue={FIRMWARE_UPGRADE_DEFAULT_TIMEOUT}
                    />
                )}
                <Checkbox
                    label={__('Fail on armed state')}
                    name="failOnArmedState"
                    defaultChecked={true}
                />
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(
        null,
        (
            dispatch,
            {selection, applianceId, upgradePackageId, panelId, packages, upgradeAll}
        ) =>
            bindActionCreators(
                {
                    handle: (timeout, failOnArmedState) => {
                        if (upgradeAll) {
                            return upgradeByFilters({
                                applianceId,
                                upgradePackageId,
                                timeout,
                                failOnArmedState,
                                panelIds: selection.toJS(),
                            })
                        } else if (selection) {
                            return upgradeBatch({
                                panelIds: selection.toJS(),
                                applianceId,
                                upgradePackageId,
                                timeout,
                                failOnArmedState,
                            })
                        } else {
                            return upgradePanel({
                                panelId,
                                packages,
                                timeout,
                                failOnArmedState,
                            })
                        }
                    },
                },
                dispatch
            )
    )
)(FirmwareUpgrade)
