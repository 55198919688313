import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'
import isUndefined from 'lodash/isUndefined'

import {
    MODULE_TYPE_BBA,
    MODULE_TYPE_GPRS,
    MODULE_TYPE_WIFI,
    MODULE_TYPE_BBA_OVER_GPRS,
} from 'constants/moduleTypes'
import moduleKATitle from 'constants/moduleKATypes'

import {
    gsmSignalLevelIcon,
    signalLevelTitle,
    networkTitle,
    networkShortTitle,
} from 'constants/signalLevelType'

import Communication from 'components/Panel/Communication'
import Tooltip from 'ipmp-react-ui/Tooltip'

export const DeviceModules = ({children}) => (
    <div className="device-modules">{children}</div>
)

export default class DeviceModule extends Component {
    static propTypes = {
        moduleType: PropTypes.oneOf([
            MODULE_TYPE_GPRS,
            MODULE_TYPE_BBA,
            MODULE_TYPE_WIFI,
            MODULE_TYPE_BBA_OVER_GPRS,
        ]).isRequired,
        module: PropTypes.shape({
            connected: PropTypes.bool,
            status: PropTypes.string,
        }),
        rssiInfo: PropTypes.shape({
            level: PropTypes.string,
            network: PropTypes.string,
        }),
    }

    renderRssi() {
        const {rssiInfo} = this.props

        if (rssiInfo === undefined || (!rssiInfo.level && !rssiInfo.network)) {
            return
        }

        const Icon = gsmSignalLevelIcon(rssiInfo.level)

        return (
            <Tooltip
                className="device-icon-holder"
                tooltip={
                    networkTitle(rssiInfo.network) +
                    ' - ' +
                    signalLevelTitle(rssiInfo.level)
                }
            >
                <Icon className="device-icon" />
                <div className="device-icon-sub">
                    {networkShortTitle(rssiInfo.network)}
                </div>
            </Tooltip>
        )
    }

    render() {
        const {moduleType, module} = this.props

        if (isUndefined(module) || module === null) {
            return null
        }

        const moduleClasses = classes('device-module', {
            'device-module--notInstalled': !module,
        })

        return (
            <div className={moduleClasses}>
                <Communication
                    moduleType={moduleType}
                    module={module}
                    showTooltip={false}
                />
                {moduleKATitle(module)}

                {this.renderRssi()}
            </div>
        )
    }
}
