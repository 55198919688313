import * as PanelApi from 'api/panel/info'
import * as ConfigurationApi from 'api/panel/configuration'
import * as Plink from 'api/panel/plink'
import * as PanelsApi from 'api/panels'
import * as RriApi from 'api/remoteInspection'
import * as ReportsApi from 'api/reports'
import * as GroupApi from 'api/group'
import * as GroupsApi from 'api/groups'
import * as UserApi from 'api/user'
import * as DevicesApi from 'api/devices'
import * as CentralStationApi from 'api/centralStations'
import * as RemoteInspectionsApi from 'api/remoteInspection'
import * as ProfileApi from 'api/profile'
import * as RolesApi from 'api/roles'
import * as AuthApi from 'api/auth'
import * as SystemSettingsApi from 'api/system/settings'
import * as InteractiveUsersApi from 'api/interactiveUsers'

import {
    PROCESS_TYPE_SET_LABEL,
    PROCESS_TYPE_PMAXZONEADD,
    PROCESS_TYPE_NEOACTIVATION,
} from 'constants/processTypes'

import createFormHandler, {
    createFormHandlerGeneratedProcess,
} from 'modules/forms/helpers/createFormHandler'

export const markForService = createFormHandler(
    'markForService',
    (ids, comment, userId = null) => PanelsApi.markForService(ids, comment, userId),
    (ids, comment, userId = null, user = null) => ({ids, comment, userId, user})
)

export const reassignService = createFormHandler(
    'reassignService',
    (ids, comment, userId = null) => PanelsApi.reassign(ids, comment, userId),
    (ids, comment, userId = null, user = null) => ({ids, comment, userId, user})
)

export const changeGroup = createFormHandler(
    'changeGroup',
    (ids, groupId) => PanelsApi.changeGroup(ids, groupId),
    (ids, groupId) => ({ids, groupId})
)

export const addPanel = createFormHandler('addPanel', PanelApi.createNew)

export const editPanelInfo = createFormHandler(
    'editPanelInfo',
    PanelApi.updateData,
    (panelId, data) => ({panelId, data})
)

export const editPanelCustomerInfo = createFormHandler(
    'editPanelCustomerInfo',
    PanelApi.updateInfo,
    (panelId, data) => ({panelId, data})
)

export const createBasicConfiguration = createFormHandler(
    'createBasicConfiguration',
    ConfigurationApi.makeBasic,
    (panelId) => ({panelId})
)

export const activatePanel = createFormHandlerGeneratedProcess(
    'activatePanel',
    PanelApi.activatePanel,
    (panelId, installerCode) => ({
        panelId,
        installerCode,
    }),
    PROCESS_TYPE_NEOACTIVATION
)

export const editGroupCsLinks = createFormHandler(
    'editGroupCsLinks',
    GroupApi.persistCentralStationLinks,
    (groupId, centralStationId, profiles) => ({groupId, centralStationId, profiles})
)

export const addGroup = createFormHandler('addGroup', GroupsApi.add)

export const editGroup = createFormHandler('editGroup', GroupsApi.edit, (id, data) => ({
    id,
    ...data,
}))

export const resolveFaults = createFormHandler(
    'resolveFaults',
    PanelsApi.resolveFaults,
    (faultIds) => ({faultIds})
)

export const suspendFaults = createFormHandler(
    'suspendFaults',
    PanelsApi.suspendFaults,
    (faultIds) => ({faultIds})
)

export const resumeFaults = createFormHandler(
    'resumeFaults',
    PanelsApi.resumeFaults,
    (faultIds) => ({faultIds})
)

export const scheduleRri = createFormHandler(
    'scheduleRri',
    (ids, date, repetition) => {
        if (date) {
            return RriApi.scheduleInspection(ids, date, repetition)
        }

        return RriApi.cancelInspection(ids)
    },
    (ids, date, repetition) => ({ids, date, repetition})
)

export const addCentralStation = createFormHandler(
    'addCentralStation',
    CentralStationApi.create
)

export const editCentralStation = createFormHandler(
    'editCentralStation',
    CentralStationApi.edit,
    (data, id) => ({
        data,
        id,
    })
)

export const editRemoteInspectionValues = createFormHandler(
    'editRemoteInspectionValues',
    RemoteInspectionsApi.setValues,
    (data) => ({...data})
)

export const createReport = createFormHandler(
    'createReport',
    ReportsApi.createReport,
    (data) => ({data})
)

export const addUser = createFormHandler('addUser', UserApi.add)

export const editUser = createFormHandler('editUser', UserApi.edit, (id, data) => ({
    id,
    ...data,
}))

export const addDevice = createFormHandlerGeneratedProcess(
    'addDevice',
    DevicesApi.addDevice,
    (panelId, zoneId, deviceId) => ({
        panelId,
        zoneId,
        deviceId,
    }),
    PROCESS_TYPE_PMAXZONEADD
)

export const editProfile = createFormHandler(
    'editProfile',
    ProfileApi.editProfile,
    (data) => ({...data})
)

export const changePassword = createFormHandler(
    'changePassword',
    ProfileApi.changePassword
)

export const rebootPlink = createFormHandler('rebootPlink', Plink.rebootPlink)

export const enablePlinkLogging = createFormHandler(
    'enablePlinkLogging',
    Plink.enablePlinkLogging
)

export const disablePlinkLogging = createFormHandler(
    'disablePlinkLogging',
    Plink.disablePlinkLogging
)

export const addRole = createFormHandler('addRole', RolesApi.add)

export const editRole = createFormHandler('editRole', RolesApi.edit)

export const removeRole = createFormHandler('removeRole', RolesApi.remove)

export const editRolePermissions = createFormHandler(
    'editRolePermissions',
    RolesApi.editPermissions
)

export const scheduleInspectionByCsvFile = createFormHandler(
    'scheduleInspectionByCsvFile',
    RemoteInspectionsApi.scheduleInspectionByCsv,
    (content) => content
)

export const renameDevice = createFormHandlerGeneratedProcess(
    'renameDevice',
    DevicesApi.renameEntity,
    (panelId, entityClass, entityId, name) => ({
        panelId,
        entityClass,
        entityId,
        name,
    }),
    PROCESS_TYPE_SET_LABEL
)

export const renamePartition = createFormHandlerGeneratedProcess(
    'renamePartition',
    DevicesApi.renamePartition,
    (panelId, partitionId, name) => ({
        panelId,
        partitionId,
        name,
    }),
    PROCESS_TYPE_SET_LABEL
)

export const remindPassword = createFormHandler('remindPassword', ({email}) =>
    AuthApi.remindPassword(email)
)

export const setSystemSettings = {
    CellularConnected: createFormHandler('setSystemSettings.CellularConnected', (data) =>
        SystemSettingsApi.setCellularConnected(data)
    ),
    BroadbandConnected: createFormHandler(
        'setSystemSettings.BroadbandConnected',
        (data) => SystemSettingsApi.setBroadbandConnected(data)
    ),
    CommonConnected: createFormHandler('setSystemSettings.CommonConnected', (data) =>
        SystemSettingsApi.setCommonConnected(data)
    ),
    Supervision: createFormHandler('setSystemSettings.Supervision', (data) =>
        SystemSettingsApi.setSupervision(data)
    ),
    RemoteInspection: createFormHandler('setSystemSettings.RemoteInspection', (data) =>
        SystemSettingsApi.setRemoteInspection(data)
    ),
    InteractiveSession: createFormHandler(
        'setSystemSettings.InteractiveSession',
        (data) => SystemSettingsApi.setInteractiveSession(data)
    ),
    UserNotification: createFormHandler('setSystemSettings.UserNotification', (data) =>
        SystemSettingsApi.setUserNotifications(data)
    ),
    Advertisement: createFormHandler('setSystemSettings.Advertisement', (data) =>
        SystemSettingsApi.setAdvertisement(data)
    ),
    MessageBroker: createFormHandler('setSystemSettings.saveMessageBroker', (data) =>
        SystemSettingsApi.saveMessageBroker(data)
    ),
    AuthorizationSettings: createFormHandler(
        'setSystemSettings.saveAuthorizationSettings',
        (data) => SystemSettingsApi.setAuthorizationSettings(data)
    ),
    CustomerInfo: createFormHandler('setSystemSettings.CustomerInfo', (data) =>
        SystemSettingsApi.setCustomerInfo(data)
    ),
    Rotation: createFormHandler('setSystemSettings.Rotation', (data) =>
        SystemSettingsApi.setRotation(data)
    ),
}

export const assignUserToPanel = createFormHandler(
    'assignUserToPanel',
    InteractiveUsersApi.assignUserToPanel
)

export const registerInteractiveUser = createFormHandler(
    'registerInteractiveUser',
    InteractiveUsersApi.register
)
