import {combineReducers} from 'redux'

import list from './list/reducer'
import transportEmails from './transportEmails/reducer'
import store from './store/reducer'
import contentOptions from './contentOptions/reducer'
import pmaxIndexes from './pmaxIndexes/reducer'

export default combineReducers({
    list,
    transportEmails,
    store,
    contentOptions,
    pmaxIndexes,
})
