import {__} from 'utils/i18n'
import {messageByError} from 'constants/errorType'

const translateMessage = (error) => {
    switch (true) {
        case error.isCancel:
            return error.message

        case error.isWrongFormatError:
            return __('Wrong response format')

        case error.isServerError:
            return messageByError(error.type, error.details)

        case error.isCommunicationError:
            return __('Communication error: %s', error.message)

        case error.originalMessage === "Network Error":
            return __("Network Error")

        case error.isRequestError:
            return __('Request error: %s', error.message)

        default:
            return __('Unknown error: %s', error.message)
    }
}

export default function apiFormat(instance) {
    return instance.interceptors.response.use(null, (error) => {
        error.originalMessage = error.message
        error.message = translateMessage(error)

        return Promise.reject(error)
    })
}
