import React, {Component} from 'react'
import nl2br from 'react-nl2br'

import withPanelInfo from 'containers/withPanelInfo'

import {ReactComponent as IconUser} from 'ipmp-react-ui/icons/user.svg'
import {ReactComponent as IconRemark} from 'ipmp-react-ui/icons/remark.svg'
import {ReactComponent as IconLocation} from 'ipmp-react-ui/icons/location.svg'

import List, {ListItem} from 'ipmp-react-ui/List'

import EditCustomerInfoButton from './Buttons/EditCustomerInfoButton'
import {info} from 'permissions/panel/info/page'

import {__} from 'utils/i18n'
import {withPermission, withRejection} from 'containers/withPermission'
import {compose} from 'redux'

class CustomerInfo extends Component {
    render() {
        const {panel} = this.props
        const {name, email, phone, address, remark} = panel.contact || {}

        const empty = <span className="empty">{__('not set')}</span>

        return (
            <div>
                <h1 className="card-header">
                    {__('Customer Info')}
                    <EditCustomerInfoButton />
                </h1>

                <List className="card-content" twoCols>
                    <ListItem Icon={IconUser}>
                        {name ? <strong>{name}</strong> : empty}

                        <div className="list-item-info">
                            {email && (
                                <p>
                                    <a href={'mailto:' + email} className="link">
                                        {email}
                                    </a>
                                </p>
                            )}
                            {phone && <p>{phone}</p>}
                        </div>
                    </ListItem>

                    <ListItem Icon={IconRemark}>
                        {remark ? nl2br(remark) : empty}
                    </ListItem>

                    <ListItem Icon={IconLocation}>
                        {address ? nl2br(address) : empty}
                    </ListItem>
                </List>
            </div>
        )
    }
}

export default compose(
    withPermission({isAllowed: info}),
    withRejection(),
    withPanelInfo()
)(CustomerInfo)
