import {handleActions} from 'redux-actions'
import has from 'lodash-es/has'

import {FAULTY_PANELS, SUSPENDED_FAULTS} from 'constants/navigationScopes'

import {
    update,
    revert,
    changePerPage,
    saveSearchScope,
    removeSearchScope,
    setLanguage,
    setAutologout,
    setAlarmsEnabled,
    setAlarmsSoundEnabled,
    setAlarmsNotificationsEnabled,
    setKeypadSoundEnabled,
    setTheme,
    setTemperature,
} from './actions'

import {DEFAULT_TEMP_UNIT} from 'utils/temperature'

const defaultState = {
    perPage: {},
    scopes: {
        panels: {
            'faulty-panels': {
                name: FAULTY_PANELS,
                filters: [{name: 'faults', value: 'active', $: 'faults$active'}],
            },
            suspended: {
                name: SUSPENDED_FAULTS,
                filters: [{name: 'faults', value: 'suspended', $: 'faults$suspended'}],
            },
        },
    },
    alarms: {
        isEnabled: true,
        isNotificationsEnabled: true,
        isSoundEnabled: true,
        isKeypadSoundEnabled: true,
    },
    temperature: DEFAULT_TEMP_UNIT,
}

export default handleActions(
    {
        [update](state, {payload}) {
            return {
                ...state,
                ...payload,
            }
        },

        [revert](state, {payload}) {
            return payload
        },

        [changePerPage](state, {payload}) {
            return {
                ...state,
                perPage: {
                    ...state.perPage,
                    ...payload,
                },
            }
        },

        [removeSearchScope](state, {payload: {page, scope}}) {
            const data = {...state.scopes[page]}

            if (!has(data, scope)) {
                return state
            }

            delete data[scope]

            return {
                ...state,
                scopes: {
                    ...state.scopes,
                    [page]: data,
                },
            }
        },

        [saveSearchScope](state, {payload: {page, scope, name, filters}}) {
            const data = {
                ...state.scopes[page],
                [scope]: {
                    name,
                    filters,
                },
            }

            return {
                ...state,
                scopes: {
                    ...state.scopes,
                    [page]: data,
                },
            }
        },

        [setLanguage](state, {payload: language}) {
            return {
                ...state,
                language,
            }
        },

        [setTemperature](state, {payload: temperature}) {
            return {
                ...state,
                temperature,
            }
        },

        [setAutologout](state, {payload: autologout}) {
            return {
                ...state,
                autologout,
            }
        },

        [setAlarmsEnabled](state, {payload: {isEnabled}}) {
            return {
                ...state,
                alarms: {
                    ...state.alarms,
                    isEnabled,
                },
            }
        },

        [setAlarmsNotificationsEnabled](state, {payload: {isEnabled}}) {
            return {
                ...state,
                alarms: {
                    ...state.alarms,
                    isNotificationsEnabled: isEnabled,
                },
            }
        },

        [setAlarmsSoundEnabled](state, {payload: {isSoundEnabled}}) {
            return {
                ...state,
                alarms: {
                    ...state.alarms,
                    isSoundEnabled,
                },
            }
        },

        [setKeypadSoundEnabled](state, {payload: {isEnabled}}) {
            return {
                ...state,
                alarms: {
                    ...state.alarms,
                    isKeypadSoundEnabled: isEnabled,
                },
            }
        },

        [setTheme](state, {payload}) {
            return {
                ...state,
                theme: payload,
            }
        },
    },
    defaultState
)
