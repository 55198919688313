import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import List from 'ipmp-react-ui/List'

import {SettingsPageBlock} from '../SettingsPageBlock'
import EditAuthorizationSettings from './Buttons/EditAuthorizationSettings'

import {__} from 'utils/i18n'

class AuthorizationSettings extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            first: PropTypes.shape({
                maxAttemptsCount: PropTypes.number,
                timeout: PropTypes.number,
                expirationPeriod: PropTypes.number,
            }),
            second: PropTypes.shape({
                maxAttemptsCount: PropTypes.number,
                timeout: PropTypes.number,
                expirationPeriod: PropTypes.number,
            }),
            email: PropTypes.shape({
                codeLength: PropTypes.number,
                expirationPeriod: PropTypes.number,
            }),
            userLifetime: PropTypes.number,
        }),
    }

    render() {
        const {data} = this.props
        return (
            <div>
                <h1 className="card-header">
                    {__('Authorization Settings')}
                    <EditAuthorizationSettings />
                </h1>

                <List className="card-content" twoCols>
                    {this.renderListItem(
                        __('First factor expiration period [Seconds]'),
                        data.first.expirationPeriod
                    )}
                    {this.renderListItem(
                        __('Email code expiration period [Seconds]'),
                        data.email.expirationPeriod
                    )}
                    {this.renderListItem(__('Email code length'), data.email.codeLength)}
                    {this.renderListItem(
                        __('Account login attempts limit'),
                        data.first.maxAttemptsCount
                    )}
                    {this.renderListItem(
                        __('Account login lock timeout [Seconds]'),
                        data.first.timeout
                    )}
                </List>
                <List className="card-content" twoCols>
                    {this.renderListItem(
                        __('Panel login attempts limit'),
                        data.second.maxAttemptsCount
                    )}
                    {this.renderListItem(
                        __('Panel login lock timeout [Seconds]'),
                        data.second.timeout
                    )}
                    {this.renderListItem(
                        __('Second factor expiration period [Seconds]'),
                        data.second.expirationPeriod
                    )}
                    {this.renderListItem(
                        __('Permanent user lifetime [Days]'),
                        data.userLifetime
                    )}
                </List>
            </div>
        )
    }
}

export default compose(
    connect(({system: {settings: {authorizationSettings}}}) => ({
        ...authorizationSettings,
    }))
)(AuthorizationSettings)
