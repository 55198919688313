import {handleActions} from 'redux-actions'
import {set} from 'immutable-modify'
import keyBy from 'lodash-es/keyBy'
import {update, fetch} from './actions'

const defaultState = {
    byIds: {},
    isLoading: false,
    error: null,
}

export default handleActions(
    {
        [fetch](state) {
            return set(state, 'isLoading', true)
        },

        [update](state, {payload, error}) {
            if (error) {
                return {
                    ...state,
                    isLoading: false,
                    error: payload,
                }
            }

            return {
                ...state,
                isLoading: false,
                error: null,
                byIds: {
                    ...state.byIds,
                    ...keyBy(payload, 'panelId'),
                },
            }
        },
    },
    defaultState
)
