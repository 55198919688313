import withProps from 'containers/withProps'

import InstallersBar from './InstallersBar'
import InstallersTable from './InstallersTable'
import InstallersSearch from './InstallersSearch'

import Page from 'ipmp-react-ui/Page'

export default withProps({
    Bar: InstallersBar,
    Top: InstallersSearch,
    Content: InstallersTable,
})(Page)
